import makeStyles from '@material-ui/core/styles/makeStyles'

export default makeStyles((theme) => ({
  city: {
    fontSize: '24px',
    fontWeight: 700,
    lineHeight: '38px',
    letterSpacing: '0px',
    textAlign: 'center',
    color: '#071332',
    marginBottom: '8px',
    '@media (max-width: 640px)': {
      fontSize: '22px',
      lineHeight: '34px',
    },
  },
}))
