import { STORE_INIT, LANGUAGE_ACTION_TYPES } from '../actionTypes'
import { urlReplaceHyphane } from '../../utils/helper'

// eslint-disable-next-line no-restricted-globals,no-undef
const urlLanguage = urlReplaceHyphane(location.pathname.split('/')[1])

const selectedLanguage = ['zh_CN', 'zh_Hant'].includes(urlLanguage) ? urlLanguage : 'en_GB'

const initialState = {
  selectedLanguage,
  languages: ['en_GB', 'zh_CN', 'zh_Hant'],
}

const locationReducer = (state = initialState, { type, payload }) => {
  switch (type) {
    case STORE_INIT: {
      const languageInURL = window.location.pathname.split('/')[1]
      const languageIsSet =
        languageInURL && initialState.languages.find((lang) => lang.toLowerCase() === languageInURL.toLowerCase())

      // apply selected language in recipie nt view
      if (languageIsSet) {
        return {
          selectedLanguage: languageInURL,
          languages: state.languages,
        }
      }

      return state
    }
    case LANGUAGE_ACTION_TYPES.SET_LANGUAGE: {
      return {
        selectedLanguage: payload,
        languages: state.languages,
      }
    }
    case LANGUAGE_ACTION_TYPES.CLEAR_LANGUAGE: {
      return {
        selectedLanguage: 'en_GB',
        languages: ['en_GB', 'zh_CN', 'zh_Hant'],
      }
    }

    default:
      return state
  }
}

export default locationReducer
