import http from 'utils/http'
import { SESSION_ACTION_TYPES, STORE_INIT, USER_ACTION_TYPES } from '../actionTypes'

const initialState = null

const sessionReducer = (state = initialState, { type, payload }) => {
  switch (type) {
    case STORE_INIT:
      http.setAuthorization(state)
      return state
    case SESSION_ACTION_TYPES.SET_SHARE_ID: {
      return state
    }
    case SESSION_ACTION_TYPES.START: {
      http.setAuthorization(payload)

      return payload
    }
    case SESSION_ACTION_TYPES.END:
      http.setAuthorization()
      return initialState
    case USER_ACTION_TYPES.LOAD_USER.COMPLETE:
      // if BE return null, meaning user is not logged in any more
      // remove session
      if (!payload) {
        http.setAuthorization()
        return initialState
      } else {
        return state
      }
    default:
      return state
  }
}

export default sessionReducer
