import React from 'react'
import makeStyles from './styles'
import { ReactComponent as GlobSVG } from 'assets/icons/glob.svg'
import { ReactComponent as ArrowDown } from 'assets/icons/arrow_down.svg'
import testIds from 'config/testIds'

const Dropdown = ({ options, onChange, selected }) => {
  const classes = makeStyles()

  const onChangeProxy = (e) => {
    onChange(e.target.value, e)
  }

  return (
    <div className={classes.container}>
      <select className={classes.dropdown} onChange={onChangeProxy} data-test-id={testIds.dropdown.select}>
        {options.map((option, i) => {
          return (
            <option
              key={i}
              selected={option.value === selected}
              value={option.value}
              data-test-id={`${testIds.dropdown.option}-${i}`}
            >
              {option.title}
            </option>
          )
        })}
      </select>
      <GlobSVG className={classes.icon} data-test-id={testIds.dropdown.leftIcon} />
      <ArrowDown className={classes.arrow} data-test-id={testIds.dropdown.rightIcon} />
    </div>
  )
}

export default Dropdown
