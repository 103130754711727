import Box from '@material-ui/core/Box'
import Grid from '@material-ui/core/Grid'
import classNames from 'classnames'
import testIds from 'config/testIds'
import PropTypes from 'prop-types'
import React from 'react'
import CardActions from '../CardActions'
import CardBottom from '../CardBottom'
import CardMiddle from '../CardMiddle'
import styles from './styles'

const Card = ({ id, dbMarque, isSold, iconAltText, media, imageSize, points, icon, selected, actions, onClick }) => {
  const classes = styles()
  const handleClick = () => {
    return onClick ? onClick(dbMarque) : null
  }

  return (
    <Box
      data-test-id={testIds.cardView.card}
      className={classNames({
        [classes.container]: true,
        [classes.selected]: selected,
      })}
      onClick={handleClick}
    >
      <Grid container direction="column">
        <Grid item>
          <Grid container direction="column">
            <Grid item className={classes.content}>
              <CardMiddle {...{ media, imageSize }} />
              <CardBottom {...{ points, isSold, icon, iconAltText }} />
            </Grid>
          </Grid>
        </Grid>
        <Grid item>{!isSold && <CardActions actions={actions} />}</Grid>
      </Grid>
    </Box>
  )
}

Card.propTypes = {
  id: PropTypes.string.isRequired,
  imageSize: PropTypes.shape({
    width: PropTypes.number,
    height: PropTypes.number,
  }).isRequired,
  onClick: PropTypes.func,
  selected: PropTypes.bool.isRequired,
  media: PropTypes.object.isRequired,
  icon: PropTypes.string.isRequired,
  points: PropTypes.arrayOf(PropTypes.string),
  actions: PropTypes.arrayOf(
    PropTypes.shape({
      events: PropTypes.shape({
        onClick: PropTypes.func,
      }),
      text: PropTypes.string.isRequired,
      disabled: PropTypes.bool.isRequired,
    }).isRequired
  ).isRequired,
}

export default Card
