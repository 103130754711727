const GTM_Event_TYPES = {
  DIAMOND_SPECIFICS: 'Diamond Specifics',
  ADD_DIAMOND: 'Add Diamond',
  REMOVE_DIAMOND: 'Remove Diamond',
  REMOVE_ALL_DIAMONDS: 'Remove All Diamond',
  LOGIN_FAILURE: 'Login Failure',
  LOGIN_SUCCESS: 'Login Success',
  LANGUAGE_SELECTION: 'Language Selection',
  EMAIL_TYPE_SHARING: 'Email Type Sharing',
  SELECT_DIAMOND: 'Select Diamond',
  CANCEL_SELECTION: 'Cancel Selection',
  CLIENT_DIAMOND_SPECIFICS: 'Client diamond specifics',
}

export default GTM_Event_TYPES
