import makeStyles from '@material-ui/core/styles/makeStyles'

export default makeStyles((theme) => ({
  diamondTitleContainer: {},
  text: {
    ...theme.typography.sizeM,
    textAlign: 'center',
    color: theme.colors.darkBlue,
    '& span': {
      fontWeight: 400,
    },
    '@media (min-width: 1024px)': {
      padding: 0,
      fontSize: '26px',
    },
    '@media (min-width: 1440px)': {
      fontSize: '34px',
    },
  },
  sizeS: {
    fontSize: '19px',
    fontWeight: 400,
    letterSpacing: '0.02px',
    '@media (min-width: 1024px)': {
      fontSize: '26px',
    },
    '@media (min-width: 1440px)': {
      fontSize: '34px',
    },
  },
}))
