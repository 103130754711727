import React from 'react'
import { Box } from '@material-ui/core'
import { path } from 'ramda'
import classnames from 'classnames'

import DiamondProp from '../DiamondProp'
import usePropsMapper from './propsMapper'
import styles from './styles'
import testIds from 'config/testIds'

const DiamondProps = ({ diamond }) => {
  const classes = styles()
  const props = usePropsMapper()

  return (
    <Box className={classes.diamondPropsContainer}>
      <Box className={classes.content}>
        <Box className={classnames(classes.column, classes.left)}>
          {props.left.map((prop) => {
            const value = prop.value ? prop.value(diamond) : path(prop.key.split('.'), diamond)
            return (
              <DiamondProp label={prop.label} value={value} key={prop.key} testId={testIds.diamond.props[prop.key]} />
            )
          })}
        </Box>

        <Box className={classnames(classes.column, classes.right)}>
          {props.right.map((prop) => {
            const value = prop.value ? prop.value(diamond) : path(prop.key.split('.'), diamond)

            return (
              <DiamondProp label={prop.label} value={value} key={prop.key} testId={testIds.diamond.props[prop.key]} />
            )
          })}
        </Box>
      </Box>
    </Box>
  )
}

export default DiamondProps
