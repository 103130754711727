const createActionSet = (prefix) => {
  return {
    REQUEST: `${prefix}_REQUEST`,
    FAILURE: `${prefix}_FAILURE`,
    COMPLETE: `${prefix}_COMPLETE`,
    CLEAR: `${prefix}`,
  }
}

export default createActionSet
