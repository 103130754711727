import { pathOr } from 'ramda'

export const getAction = (id, dbMarque, selected, editMode, onSelect, onDeselect, translations) => {
  if (selected) {
    return {
      events: {
        onClick: (e) => {
          e.stopPropagation()
          onDeselect(dbMarque)
        },
      },
      text: translations.list.remove,
      disabled: false,
      selected: true,
    }
  }

  return {
    events: {
      onClick: (e) => {
        e.stopPropagation()
        onSelect(dbMarque)
      },
    },
    text: translations.list.add,
    disabled: false,
    selected: false,
  }
}

const getCarat = (diamond, translations) => {
  const caratsValue = diamond.caratWeight
  return caratsValue ? ` ${caratsValue.toFixed(2)}${translations.diamondExperience.props.caratsShort}` : '-'
}

const getGeneric = (diamond, key) => pathOr('-', [`${key}`], diamond)

const getImage = (diamond) => diamond.header.diamondImage

const toCard = (translations) => (diamond) => {
  return {
    id: diamond.id,
    dbMarque: diamond.dbMarque,
    isSold: diamond.isSold,
    icon: pathOr('', ['provenance', 'provenance', 'flag', 'url'], diamond),
    iconAltText: pathOr('', ['provenance', 'provenance', 'flag', 'alternativeText'], diamond),
    media: {
      diamondId: diamond.tracrId,
      imageProps: getImage(diamond),
    },
    points: [
      pathOr('DTC', ['provenance', 'provenance', 'name'], diamond),
      getCarat(diamond, translations),
      getGeneric(diamond, 'cut'),
      getGeneric(diamond, 'clarity'),
      `${getGeneric(diamond, 'colour')} ${translations.diamondExperience.props.colour}`,
    ],
  }
}

export default toCard
