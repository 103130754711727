import makeStyles from '@material-ui/core/styles/makeStyles'

export default makeStyles((theme) => ({
  container: {
    position: 'fixed',
    top: 0,
    width: '100%',
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    padding: '24px',
    '@media (min-width: 640px)': {
      padding: '24px 32px',
    },
    boxShadow: '0px 2px 20px rgba(0, 0, 0, 0.06)',
    background: '#fff',
  },
  left: {},
  right: {
    textAlign: 'right',
    '@media (min-width: 640px)': {
      textAlign: 'center',
      position: 'absolute',
      left: '50%',
      transform: 'translateX(-50%)',
    },
  },
  long: {
    display: 'none',
    '@media (min-width: 640px)': {
      display: 'block',
    },
  },
  short: {
    display: 'block',
    '@media (min-width: 640px)': {
      display: 'none',
    },
  },
  text: {
    fontFamily: 'Montserrat',
    fontWeight: 500,
    letterSpacing: '3px',
    textTransform: 'uppercase',
  },
}))
