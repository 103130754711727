export const defaultStyles = {
  typography: {
    fontFamily: ['Didot'],
    common: {
      fontFamily: 'Didot',
    },
    sizeXS: {
      fontSize: 15,
      fontWeight: 400,
      lineHeight: '19px',
      letterSpacing: '0.01px',
    },
    sizeS: {
      fontSize: 15,
      fontWeight: 400,
      lineHeight: '19px',
      letterSpacing: '0.01px',
    },
    sizeM: {
      fontSize: 19,
      fontWeight: 400,
      lineHeight: '157.4%',
      letterSpacing: '0.02px',
    },
    sizeXL: {
      fontSize: 36,
      fontWeight: 400,
    },
  },
  colors: {
    white: '#FFF',
    darkBlue: '#001E42',
    darkerBlue: '#001E42',
    backgroundGrey: '#fafafa',
  },
  shadows: ['0px 2px 20px rgba(0, 0, 0, 0.06)'],
  overrides: {
    MuiLink: {
      root: {
        color: '#071332',
        fontWeight: 700,
        cursor: 'pointer',
        letterSpacing: 0,
        textTransform: 'none',
      },
    },
    MuiInput: {
      input: {
        fontSize: 14,
        color: '#000',
      },
    },
    MuiButton: {
      root: {
        '&:hover': {
          backgroundColor: '#071332',
          '@media (hover: none)': {
            backgroundColor: '#071332',
          },
        },
        '&$disabled': {
          color: '#CBCDD4',
          background: '#999DAB',
        },
      },
      label: {
        textTransform: 'none',
      },
    },
  },
}
