import React from 'react'
import Dialog from '@material-ui/core/Dialog'
import DialogActions from '@material-ui/core/DialogActions'
import DialogContent from '@material-ui/core/DialogContent'
import DialogContentText from '@material-ui/core/DialogContentText'
import DialogTitle from '@material-ui/core/DialogTitle'
import Button from '@material-ui/core/Button'
import withMobileDialog from '@material-ui/core/withMobileDialog'
import useLanguage from 'locale'

const ResetPasswordDialog = ({ open, handleDialogClose, resetSuccessMsg }) => {
  const translations = useLanguage()
  return (
    <Dialog open={open} onClose={handleDialogClose} aria-labelledby="responsive-dialog-title">
      <DialogTitle id="responsive-dialog-title">
        {translations.resetPassword.messages.customerView.resetDialogTitle}
      </DialogTitle>
      <DialogContent>
        <DialogContentText>{resetSuccessMsg}</DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button onClick={handleDialogClose} color="primary" autoFocus>
          {translations.resetPassword.button.okButtonText}
        </Button>
      </DialogActions>
    </Dialog>
  )
}

export default withMobileDialog()(ResetPasswordDialog)
