import React from 'react'
import { useDispatch } from 'react-redux'
import Banner from 'components/Banner'
import { ReactComponent as SuccessSVG } from 'assets/icons/success.svg'
import { clearSelectedDiamondAction } from 'store/actions'

import TracrLogo from 'assets/tracr-logo-light.png'
import styles from './styles'
import { useHistory } from 'react-router'
import { Grid } from '@material-ui/core'
import testIds from 'config/testIds'
import useLanguage from 'locale'

const Success = () => {
  const classes = styles()
  const history = useHistory()
  const translations = useLanguage()
  const dispatch = useDispatch()

  const handleRedirect = () => {
    // Clearing previously selected diamond that
    // is just shared
    dispatch(clearSelectedDiamondAction())
    history.push('/list')
  }

  return (
    <Grid container direction="column" justify="space-around" alignItems="center" className={classes.container}>
      <Grid item>
        <Banner padding={0} variant="light" />
      </Grid>
      <Grid item>
        <Grid container direction="column" alignItems="center" justify="center">
          <Grid item>
            <SuccessSVG />
          </Grid>
          <Grid item className={classes.text} data-test-id={testIds.success.text}>
            {translations.success.shared}
          </Grid>
          <Grid item>
            <button className={classes.button} onClick={handleRedirect} data-test-id={testIds.success.back}>
              {translations.success.button}
            </button>
          </Grid>
        </Grid>
      </Grid>
      <Grid item>
        <Grid container alignItems="center">
          <Grid item className={classes.footer} data-test-id={testIds.success.footerText}>
            {translations.success.assuredBy}
          </Grid>
          <Grid item>
            <img className={classes.footerLogo} src={TracrLogo} alt={'Tracr'} />
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  )
}

export default Success
