import makeStyles from '@material-ui/core/styles/makeStyles'

export default makeStyles((theme) => ({
  container: {
    marginTop: '16px',
    padding: '0 24px',
  },
  item: {
    fontFamily: 'Montserrat',
    fontSize: theme.typography.subtitle2.fontSize,
    lineHeight: '15px',
    marginBottom: '8px',
    fontWeight: 500,
  },
  firstItem: {
    margin: 0,
  },
  lastItem: {
    margin: 0,
  },
  logo: {
    width: '48px',
    paddingRight: '16px',
    '& svg': {
      display: 'block',
    },
  },
  soldLabel: {
    fontFamily: 'Montserrat',
    fontWeight: 800,
    fontSize: 18,
    color: '#33cc00',
  },
}))
