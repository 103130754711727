import React from 'react'
import PropTypes from 'prop-types'
import Grid from '@material-ui/core/Grid'
import classNames from 'classnames'

import testIds from 'config/testIds'
import styles from './styles'

const CardBottom = ({ icon, iconAltText, isSold, points }) => {
  const classes = styles()

  const ShowSoldLabel = () => {
    return (
      <Grid item>
        <Grid container direction={'row'} alignItems="center" justify="center">
          <div className={classes.soldLabel}>SOLD</div>
        </Grid>
      </Grid>
    )
  }

  return (
    <Grid container className={classes.container} direction={'column'}>
      {isSold && <ShowSoldLabel />}
      {points.map((point, index) => (
        <Grid item key={index}>
          <Grid container direction={'row'} alignItems="center">
            <Grid data-test-id={testIds.cardView.cardFlag} item className={classes.logo}>
              <div>{index === 0 ? <img src={icon} alt={iconAltText} height={32} width={32} /> : ''}</div>
            </Grid>
            <Grid
              data-test-id={testIds.cardView.cardDataPoint}
              item
              className={classNames({
                [classes.item]: true,
                [classes.firstItem]: index === 0,
                [classes.lastItem]: index === points.length - 1,
              })}
            >
              <div>{point}</div>
            </Grid>
          </Grid>
        </Grid>
      ))}
    </Grid>
  )
}

CardBottom.propTypes = {
  icon: PropTypes.string.isRequired,
  points: PropTypes.arrayOf(PropTypes.string),
}

export default CardBottom
