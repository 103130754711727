import React from 'react'
import { Box } from '@material-ui/core'
import classnames from 'classnames'

import Title from '../Title'
import styles from './styles'

const DiamondTitle = ({ diamond, size = 'm', mobile, lang, testId }) => {
  const classes = styles()
  return (
    <Box className={classes.diamondTitleContainer}>
      <Box data-test-id={testId} className={classnames(classes.text, { [classes.sizeS]: size === 's' })}>
        <Title diamond={diamond} mobile={mobile} lang={lang} />
      </Box>
    </Box>
  )
}

export default DiamondTitle
