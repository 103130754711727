import makeStyles from '@material-ui/core/styles/makeStyles'

export default makeStyles((theme) => ({
  email: {
    fontSize: '16px',
    fontWeight: 600,
    lineHeight: '20px',
    textAlign: 'center',
    color: '#071332',
    marginTop: '16px',
    '@media (max-width: 640px)': {
      fontSize: '14px',
      lineHeight: '22px',
    },
  },
}))
