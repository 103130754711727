import { Grid, Input, TextareaAutosize, useMediaQuery } from '@material-ui/core'
import { useTheme } from '@material-ui/styles'
import classNames from 'classnames'
import Banner from 'components/Banner'
import PageButton from 'components/PageButton'
import TagManager from 'components/TagManager'
import testIds from 'config/testIds'
import { Header } from 'containers/Header'
import GTM_Event_TYPES from 'GTM/model'
import { deployGTMDataLayer } from 'GTM/service'
import useLanguage from 'locale'
import { isEqual } from 'lodash'
import React, { useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useHistory } from 'react-router'
import { shareDiamondsAction } from 'store/actions'
import { selectEmailTemplate, selectSelectedLanguage } from 'store/selectors'
import { enhancedUrl } from 'utils/helper'
import makeStyles from './styles'

const EMAIL_REGEXP =
  /(?:[a-zA-Z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-zA-Z0-9!#$%&'*+/=?^_`{|}~-]+)*|"(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21\x23-\x5b\x5d-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])*")@(?:(?:[a-zA-Z0-9](?:[a-zA-Z0-9-]*[a-zA-Z0-9])?\.)+[a-zA-Z0-9](?:[a-zA-Z0-9-]*[a-zA-Z0-9])?|\[(?:(?:(2(5[0-5]|[0-4][0-9])|1[0-9][0-9]|[1-9]?[0-9]))\.){3}(?:(2(5[0-5]|[0-4][0-9])|1[0-9][0-9]|[1-9]?[0-9])|[a-zA-Z0-9-]*[a-zA-Z0-9]:(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21-\x5a\x53-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])+)\])/g // eslint-disable-line no-control-regex
const INTERNATIONAL_MOBILE_REGEX = /^\+[1-9]{1}[0-9]{6,25}$/g

// TODO(tracr): to add more buttons CSS needed to be adjusted.
// Feature not added in this scope
const Share = ({ location }) => {
  const classes = makeStyles()
  const dispatch = useDispatch()
  const history = useHistory()
  const translations = useLanguage()
  const language = useSelector(selectSelectedLanguage)

  const processEmailTemplates = (emailTempArr) => {
    const emailTempObj = {
      first: {},
      second: {},
      third: {},
    }
    if (emailTempArr.length > 0) {
      emailTempArr.forEach((eachTemplate) => {
        if (eachTemplate.position === 1) {
          emailTempObj.first = eachTemplate
        } else if (eachTemplate.position === 2) {
          emailTempObj.second = eachTemplate
        } else if (eachTemplate.position === 3) {
          emailTempObj.third = eachTemplate
        }
      })
    }
    return emailTempObj
  }

  const emailTemplateObj = processEmailTemplates(useSelector(selectEmailTemplate))

  const theme = useTheme()
  const isMobile = useMediaQuery(theme.breakpoints.down('md'), {
    defaultMatches: true,
  })

  const [emailValidationData, setEmailValidationData] = useState([])
  const [phoneValidationData, setPhoneValidationData] = useState([])
  const [data, setData] = useState({ emailAddresses: [], phoneNumbers: [], subject: '', message: '', locale: '' })
  const [focus, setFocus] = useState(null)
  const [emailTemplateType, setEmailTemplateType] = useState('')

  const validateEmail = (value) => !!value.match(EMAIL_REGEXP) && value.match(EMAIL_REGEXP)[0] === value
  const validatePhone = (value) => {
    const passesRegex = !!value.match(INTERNATIONAL_MOBILE_REGEX)
    const containsRestrictedCode = value.includes('+86') || value.includes('+1')

    return passesRegex && !containsRestrictedCode
  }

  const onEmailsUpdated = (tags) => {
    const emailAddresses = tags.map((tag) => tag.valid)

    if (!isEqual(emailAddresses, emailValidationData)) {
      setEmailValidationData(emailAddresses)
    }

    const newData = { ...data, emailAddresses: [...new Set(tags.map((tag) => tag.value))] }

    if (!isEqual(data, newData)) {
      setData(newData)
    }
  }

  const onPhonesUpdated = (tags) => {
    const numbers = tags.map((tag) => tag.valid)

    if (!isEqual(numbers, phoneValidationData)) {
      setPhoneValidationData(numbers)
    }

    const newData = { ...data, phoneNumbers: [...new Set(tags.map((tag) => tag.value))] }

    if (!isEqual(data, newData)) {
      setData(newData)
    }
  }

  const hasMaxCharacterError = () => phoneValidationData.length > 0 && data.message && data.message.length > 500

  const hasMinCharacterError = () =>
    (phoneValidationData.length > 0 || emailValidationData.length > 0) && data.message.length === 0

  const hasEmailEmptySubjectError = () => emailValidationData.length > 0 && data.subject.length === 0

  const isFormValid = () => {
    const validations = [...emailValidationData, ...phoneValidationData]

    if (emailValidationData.length) {
      validations.push(emailValidationData.length && data.subject)
    }

    if (hasMaxCharacterError()) {
      return false
    }

    if (hasMinCharacterError()) {
      return false
    }

    if (hasEmailEmptySubjectError()) {
      return false
    }

    return validations.length && !validations.some((elem) => !elem)
  }

  const onSubmit = () => {
    const locale = enhancedUrl(language)
    const body = { ...data, diamonds: location.state.dbMarques, locale }
    dispatch(shareDiamondsAction(body)).then(() => history.push(`/${locale}/success`))
    deployGTMDataLayer({
      event: GTM_Event_TYPES.EMAIL_TYPE_SHARING,
      sharedEmailType: emailTemplateType,
      diamondCount: location.state.dbMarques.length,
      dbMarques: location.state.dbMarques.map((eachDbMarque) => ({ dbMarque: eachDbMarque })),
    })
  }

  const onInputFocus = (type) => {
    setFocus(type)
  }

  const onInputBlur = () => {
    setFocus(null)
  }

  const TitleComponent = () => {
    if (!location.state) {
      return null
    } else if (location.state.dbMarques.length === 1) {
      return translations.share.selectedSingular
    } else {
      return translations.share.selectedPlural(location.state.dbMarques.length)
    }
  }

  const applyTemplate = (type) => {
    switch (type) {
      case 1: {
        setEmailTemplateType(emailTemplateObj.first.name)
        return setData({
          ...data,
          subject: emailTemplateObj.first.subject,
          message: emailTemplateObj.first.message,
        })
      }
      case 2: {
        setEmailTemplateType(emailTemplateObj.second.name)
        return setData({
          ...data,
          subject: emailTemplateObj.second.subject,
          message: emailTemplateObj.second.message,
        })
      }
      case 3:
        setEmailTemplateType(emailTemplateObj.third.name)
        return setData({
          ...data,
          subject: emailTemplateObj.third.subject,
          message: emailTemplateObj.third.message,
        })
      default:
    }
  }

  // redirect to list if there is no diamonds selected
  if (!location.state) {
    const urlLanguage = location.pathname.split('/')[1]
    history.push(`/${enhancedUrl(urlLanguage)}/list`)
  }

  const gotoListView = () => {
    history.push('/')
  }

  const getPriorityError = (number, index) => {
    if (number.includes('+86')) {
      return translations.share.smsError86
    }

    if (number.includes('+1')) {
      return translations.share.smsError1
    }

    if (number.includes('+') === false) {
      return translations.share.smsErrorDialCode
    }

    if (phoneValidationData[index] === false) {
      return translations.share.smsError
    }

    return null
  }

  const smsErrorMessages = Array.from(new Set(data.phoneNumbers.map(getPriorityError).filter((x) => x)))

  return (
    <div className={classes.container}>
      <Header
        action={() => history.push(`/${enhancedUrl(language)}/list`)}
        actionActive={true}
        actionText={() => <>{translations.share.editSelection}</>}
        onBack={gotoListView}
      >
        <span data-test-id={testIds.share.headerTitle}>
          <TitleComponent />
        </span>
      </Header>
      <Grid item className={classes.banner}>
        <Banner imageWidth={368} padding={'0'} />
      </Grid>
      <Grid item className={classes.content}>
        <div className={classes.formElement}>
          <Grid item className={classes.item}>
            <Grid container spacing={isMobile ? 2 : 4} className={classes.templateButtons}>
              {emailTemplateObj.first.position === 1 && (
                <Grid item xs={12} md={4}>
                  <button
                    className={classes.templateButton}
                    onClick={() => applyTemplate(1)}
                    data-test-id={testIds.share.templateButtonOne}
                  >
                    {emailTemplateObj.first.name}
                  </button>
                </Grid>
              )}
              {emailTemplateObj.second.position === 2 && (
                <Grid item xs={12} md={4}>
                  <button
                    className={classes.templateButton}
                    onClick={() => applyTemplate(2)}
                    data-test-id={testIds.share.templateButtonTwo}
                  >
                    {emailTemplateObj.second.name}
                  </button>
                </Grid>
              )}
              {emailTemplateObj.third.position === 3 && (
                <Grid item xs={12} md={4}>
                  <button
                    className={classes.templateButton}
                    onClick={() => applyTemplate(3)}
                    data-test-id={testIds.share.templateButtonThree}
                  >
                    {emailTemplateObj.third.name}
                  </button>
                </Grid>
              )}
            </Grid>
          </Grid>
          <Grid item className={classes.item}>
            <Grid container alignItems="baseline">
              <Grid item className={classNames(classes.title, classes.titleEmail)}>
                {translations.share.email}
              </Grid>
            </Grid>
          </Grid>
          <Grid item>
            <div
              className={classNames(classes.inputContainer, {
                [classes.inputContainerActive]: focus === 'email',
                [classes.inputContainerError]: emailValidationData.includes(false),
                [classes.textareaError]: hasEmailEmptySubjectError(),
              })}
            >
              <Grid container spacing={1} wrap="nowrap" className={classes.emailInputContainer}>
                <Grid item className={classes.inputLabel} data-test-id={testIds.share.emailLabel}>
                  {translations.share.to}
                </Grid>
                <Grid item className={classes.emailInput} data-test-id={testIds.share.emailInput}>
                  <TagManager
                    type="email"
                    onFocus={() => onInputFocus('email')}
                    onBlur={onInputBlur}
                    placeholder={translations.share.enterEmailAddress}
                    delimiters={[
                      { value: ';', replace: true },
                      { value: ',', replace: true },
                    ]}
                    allowDuplicates={true}
                    allowEditing={true}
                    validation={validateEmail}
                    onUpdate={onEmailsUpdated}
                  />
                </Grid>
              </Grid>

              <Grid container alignItems="center" spacing={1}>
                <Grid item className={classes.inputLabel} data-test-id={testIds.share.subjectLabel}>
                  {translations.share.subject}
                </Grid>
                <Grid item className={classes.emailInput} data-test-id={testIds.share.subjectInput}>
                  <Input
                    inputProps={{ maxLength: '100' }}
                    classes={{ input: classes.textInput, root: classes.emailRoot }}
                    onChange={(e) => setData({ ...data, subject: e.target.value })}
                    onFocus={() => onInputFocus('email')}
                    onBlur={onInputBlur}
                    placeholder={translations.share.enterSubject}
                    disableUnderline={true}
                    value={data.subject}
                  />
                </Grid>
              </Grid>
            </div>
            {hasEmailEmptySubjectError() && (
              <Grid item className={classes.errorMessage}>
                {translations.share.emptySubjectError}
              </Grid>
            )}
            {emailValidationData.includes(false) && (
              <Grid item className={classes.errorMessage} data-test-id={testIds.share.emailError}>
                {translations.share.emailError}
                {translations.share.fullStop}
              </Grid>
            )}
          </Grid>
        </div>
        <div className={classes.formElement}>
          <Grid item className={classes.item}>
            <Grid container alignItems="baseline" spacing={1}>
              <Grid item className={classes.title}>
                {translations.share.sms}
              </Grid>
              <Grid item className={classes.info}>
                {translations.share.smsInfo}
              </Grid>
            </Grid>
          </Grid>
          <Grid item>
            <Grid
              container
              wrap="nowrap"
              className={classNames(classes.inputContainer, {
                [classes.inputContainerActive]: focus === 'mobile',
                [classes.inputContainerError]: smsErrorMessages.length > 0,
              })}
            >
              <Grid item className={classes.phoneInputLabel} data-test-id={testIds.share.smsLabel}>
                {translations.share.toSms}
              </Grid>
              <Grid item className={classes.emailInput} data-test-id={testIds.share.smsInput}>
                <TagManager
                  type="tel"
                  onFocus={() => onInputFocus('mobile')}
                  onBlur={onInputBlur}
                  placeholder={translations.share.enterMobile}
                  beforeDelimiters={[{ search: ' ', replace: '' }]}
                  afterDelimiters={[{ search: '[^0-9+]', replace: '' }]}
                  delimiters={[
                    { value: ';', replace: true },
                    { value: ',', replace: true },
                    { value: '+', replace: false },
                  ]}
                  allowDuplicates={true}
                  allowEditing={true}
                  validation={validatePhone}
                  onUpdate={onPhonesUpdated}
                />
              </Grid>
            </Grid>
          </Grid>
          {smsErrorMessages.length > 0 && (
            <Grid item className={classes.errorMessage} data-test-id={testIds.share.smsError}>
              {smsErrorMessages.join(`${translations.share.fullStop} `)}
              {translations.share.fullStop}
            </Grid>
          )}
        </div>
        <div className={classes.formElement}>
          <Grid item className={classes.title}>
            {translations.share.addMessage}
          </Grid>
          <Grid item>
            <TextareaAutosize
              className={classNames(classes.textarea, {
                [classes.textareaError]: hasMaxCharacterError() || hasMinCharacterError(),
              })}
              onChange={(e) => setData({ ...data, message: e.target.value })}
              rowsMin={7}
              placeholder={translations.share.addMessageInfo}
              data-test-id={testIds.share.messageInput}
              value={data.message}
            />
          </Grid>
          {hasMaxCharacterError() && (
            <Grid item className={classes.errorMessage}>
              {translations.share.smsLimit}
            </Grid>
          )}
          {hasMinCharacterError() && (
            <Grid item className={classes.errorMessage}>
              {translations.share.emptyMsgError}
            </Grid>
          )}
          <Grid item className={classes.privacyInfo}>
            {translations.share.terms}{' '}
            <a
              target="_blank"
              rel="noopener noreferrer"
              href={translations.share.termsUrl}
              data-test-id={testIds.share.privacyPolicyLink}
            >
              {translations.share.termsLink}
            </a>
          </Grid>
        </div>
      </Grid>
      <PageButton onClick={onSubmit} disabled={!isFormValid()}>
        {translations.share.shareButton}
      </PageButton>
    </div>
  )
}

export default Share
