import createActionSet from 'utils/createActionSet'

const GET_DIAMOND = createActionSet('GET_DIAMOND')
const SHARE_DIAMOND = createActionSet('SHARE_DIAMOND')
const SHARE_DIAMOND_TO_EMAIL = createActionSet('SHARE_DIAMOND_TO_EMAIL')
const GET_SHARED_DIAMONDS = createActionSet('GET_SHARED_DIAMONDS')

const GET_DIAMONDS = createActionSet('GET_DIAMONDS')

const CLEAR_DIAMONDS = createActionSet('CLEAR')

export const DIAMONDS_ACTION_TYPES = {
  GET_DIAMONDS,
  SHARE_DIAMOND,
  SHARE_DIAMOND_TO_EMAIL,
  GET_SHARED_DIAMONDS,
  GET_DIAMOND,
  CLEAR_DIAMONDS,
}
