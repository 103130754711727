import env from '@beam-australia/react-env'

function isProductionEnvironment() {
  return env('ENV') === 'production'
}

function validEmail(email) {
  const re =
    /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
  return re.test(String(email).toLowerCase())
}

function enhancedUrl(lang) {
  return lang.replace(/_/, '-')
}

function urlReplaceHyphane(lang) {
  return lang.replace(/-/, '_')
}

function getCorrectUrlLanguage(location) {
  return `/en-GB/${location.pathname.split('/').slice(2).join('/')}`
}

function diamondDbMarqueValid(availableDiamonds, dbMarque) {
  let retVal = false
  if (availableDiamonds.find((eachDiamond) => eachDiamond.dbMarque === dbMarque)) {
    retVal = true
  }
  return retVal
}

function isBearerTokenValid(bearerToken) {
  const currentTimeStamp = Math.round(new Date().getTime() / 1000)
  const sessionValidTimeStamp = JSON.parse(window.atob(bearerToken.split('.')[1])).exp

  return sessionValidTimeStamp - currentTimeStamp > 0
}

export {
  validEmail,
  enhancedUrl,
  urlReplaceHyphane,
  getCorrectUrlLanguage,
  diamondDbMarqueValid,
  isBearerTokenValid,
  isProductionEnvironment,
}
