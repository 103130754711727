import makeStyles from '@material-ui/core/styles/makeStyles'

export default makeStyles(() => ({
  container: {
    fontFamily: 'Montserrat',
    background: 'url(images/background_dark.png) center center / cover no-repeat, #0D1B40',
    height: '100vh',
    color: '#fff',
  },
  button: {
    marginTop: 50,
    display: 'block',
    width: '224px',
    height: '40px',
    background: '#fff',
    color: '#0D1B40',
    fontSize: '12px',
    fontWeight: 600,
    border: 0,
    margin: '0 auto',
    cursor: 'pointer',
  },
  text: {
    fontSize: 30,
    fontWeight: 600,
    marginTop: 36,
    maxWidth: 400,
    textAlign: 'center',
  },
  footer: {
    fontWeight: 600,
    fontSize: 18,
    textTransform: 'uppercase',
    marginRight: 24,
    letterSpacing: '7px',
  },
  footerLogo: {
    maxWidth: 136,
  },
}))
