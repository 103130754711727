import makeStyles from '@material-ui/core/styles/makeStyles'

export default makeStyles((theme) => ({
  container: {
    display: 'flex',
    flexWrap: 'wrap',
    width: '100%',
  },
  root: {
    width: '100%',
    minWidth: 30,
    flex: 2,
  },
  input: {
    fontSize: 18,
    color: '#000',
    margin: '4px 0',
    fontFamily: 'Montserrat',
  },
}))
