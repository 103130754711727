const messages = {
  login: {
    failure: 'Failed to login',
    success: 'Login successful',
    404: 'Incorrect username or password',
    500: 'Server error, status 500',
  },
  logout: {
    success: 'Logout successful',
  },
  search: {
    failure: 'Failed to search',
    404: 'Not found',
  },
  unknownError: 'Unknown error occured',
  session: {
    expired: 'Session has expired',
  },
}

export default messages
