import React from 'react'
import testIds from 'config/testIds'

import styles from './styles'

const Line = ({ children }) => {
  const classNames = styles()

  return (
    <div className={classNames.line} data-test-id={testIds.address.line}>
      {children}
    </div>
  )
}

export default Line
