/* eslint-disable */
import React, { useEffect, useRef, useState } from 'react'
import * as THREE from 'three'
import { OrbitControls } from 'three/examples/jsm/controls/OrbitControls'
import { TrackballControls } from 'three/examples/jsm/controls/TrackballControls'
import { STLLoader } from 'three/examples/jsm/loaders/STLLoader'

const Viewer3D = ({
  maxSize = 600,
  assetUrl,
  scaled = true,
  defaultSurfaceColour = '#b0b0b0',
  defaultBackgroundColour = '#dedef0',
}) => {
  const containerElement = useRef()
  const [config, setConfig] = useState({
    autoRotate: true,
    surfaceColour: defaultSurfaceColour,
    backgroundColour: defaultBackgroundColour,
    directionalLight: 0.3,
  })
  const requestRef = React.useRef()

  // 1. Set-up renderer
  const renderer = useRef()

  const loadModel = () => {
    return new Promise((resolve, reject) => {
      new STLLoader().load(
        assetUrl,
        (geometry) => {
          resolve(geometry)
        },
        () => {
          // This is intentional
        },
        (event) => {
          reject(event)
        }
      )
    })
  }

  useEffect(() => {
    if (!containerElement.current) {
      return
    }

    renderer.current = new THREE.WebGLRenderer({ antialias: true, alpha: true })
    containerElement.current.appendChild(renderer.current.domElement)

    const size = containerElement.current.offsetWidth > maxSize ? maxSize : containerElement.current.offsetWidth

    renderer.current.setPixelRatio(window.devicePixelRatio)
    renderer.current.setSize(size, size)
  }, [])

  useEffect(() => {
    const handleResize = () => {
      const size = containerElement.current.offsetWidth > maxSize ? maxSize : containerElement.current.offsetWidth
      renderer.current.setSize(size, size)
    }
    window.addEventListener('resize', handleResize)
    return () => {
      window.removeEventListener('resize', handleResize)
    }
  })

  // 2. Set-up scene
  useEffect(() => {
    const fn = async () => {
      if (!containerElement.current) {
        return
      }

      let geometry
      try {
        geometry = await loadModel()
      } catch (error) {
        console.error('error', error)
        return
      }

      if (containerElement.current) {
        const scene = new THREE.Scene()
        scene.background = new THREE.Color('#fff')
        const camera = new THREE.PerspectiveCamera(
          45,
          containerElement.current.offsetWidth / containerElement.current.offsetHeight,
          0.1,
          1000
        )

        camera.position.x = 150
        camera.position.y = 150
        camera.position.z = 150
        camera.lookAt(new THREE.Vector3(0, 40, 0))

        const lightHolder = new THREE.Group()

        const directionalLight_1 = new THREE.DirectionalLight(0xffffff, config.directionalLight)
        directionalLight_1.position.set(1, 1, 0).normalize()
        lightHolder.add(directionalLight_1)

        const directionalLight_2 = new THREE.DirectionalLight(0xffffff, config.directionalLight)
        directionalLight_2.position.set(-1, -1, 0).normalize()
        lightHolder.add(directionalLight_2)
        scene.add(lightHolder)

        const light = new THREE.AmbientLight('#FFF')
        scene.add(light)

        containerElement.current.appendChild(renderer.current.domElement)

        let controls = null

        if (config.autoRotate) {
          controls = new OrbitControls(camera, renderer.current.domElement)
          // controls.enableZoom = true
          controls.enableZoom = false
        } else {
          controls = new TrackballControls(camera, renderer.current.domElement)
          controls.enableZoom = false
        }

        let group = new THREE.Object3D()
        const mat = new THREE.MeshLambertMaterial({
          color: config.surfaceColour,
        })

        geometry.center()
        geometry.computeBoundingBox()
        geometry.computeVertexNormals()

        const width = geometry.boundingBox.max.x - geometry.boundingBox.min.x
        const scale = containerElement.current.offsetWidth / width

        group = new THREE.Mesh(geometry, mat)
        group.scale.set(scale, scale, scale)
        if (scaled) {
          group.scale.multiplyScalar(0.2)
        }
        group.castShadow = true
        group.receiveShadow = true
        scene.add(group)

        const render = () => {
          if (group) {
            group.rotation.z += 0.006
          }

          requestAnimationFrame(render)
          renderer.current.render(scene, camera)
        }

        render()
      }
    }

    fn()

    return () => cancelAnimationFrame(requestRef.current)
  }, [config.autoRotate, config.surfaceColour, config.backgroundColour, config.directionalLight])

  return (
    <div
      style={{
        display: 'flex',
        justifyContent: 'center',
        height: '100%',
        width: '100%',
        position: 'relative',
        backgroundColor: '#FFF',
      }}
      ref={containerElement}
    ></div>
  )
}

export default Viewer3D
