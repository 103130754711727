import { INTRODUCTION_ACTION_TYPES } from '../actionTypes'
import API from 'api'

export function getIntroductionAction(lang) {
  return (dispatch) => {
    return API.getIntroduction(lang).then(
      (response) => {
        return dispatch({
          type: INTRODUCTION_ACTION_TYPES.SET_INTRODUCTION,
          payload: response.data,
        })
      },
      (error) => {
        console.log('Introduction fetching failed: ', error.message)
      }
    )
  }
}

export function clearIntroductionAction() {
  return (dispatch) => {
    dispatch({ type: INTRODUCTION_ACTION_TYPES.CLEAR_INTRODUCTION })
  }
}
