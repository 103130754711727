import makeStyles from '@material-ui/core/styles/makeStyles'

export default makeStyles((theme) => ({
  diamondContainer: {
    backgroundColor: theme.colors.backgroundGrey,
  },
  soldSharedDiamond: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    textAlign: 'center',
    color: theme.colors.darkBlue,
    '@media (min-width: 320px)': {
      fontSize: '12px',
      marginBottom: '100px',
    },
    '@media (min-width: 375px)': {
      fontSize: '14px',
      marginBottom: '50px',
    },
    '@media (min-width: 425px)': {
      fontSize: '16px',
      marginBottom: '50px',
    },
    '@media (min-width: 768px)': {
      fontSize: '16px',
    },
    '@media (min-width: 1024px)': {
      fontSize: '20px',
    },
    '@media (min-width: 1440px)': {
      fontSize: '24px',
    },
  },
  headerLink: {
    display: 'flex',
    textAlign: 'right',
    textTransform: 'uppercase',
  },
  content: {
    paddingTop: '69px',
    backgroundColor: theme.colors.white,
    '@media (min-width: 1024px)': {
      maxWidth: '1440px',
      margin: '0 auto',
    },
    '@media (min-width: 1440px)': {},
  },
  header: {
    position: 'relative',
  },
  headerBackground: {
    position: 'absolute',
    top: 0,
    width: '100%',
    height: '85%',
    overflow: 'hidden',
    '& img': {
      position: 'absolute',
      top: 0,
      maxWidth: '1024px',
      width: 'auto',
    },
    '@media (min-width: 1024px)': {
      '& img': {
        maxWidth: '1440px',
        width: 'auto',
      },
    },
    '@media (min-width: 1440px)': {
      height: '70%',
      '& img': {
        maxWidth: 'none',
        width: '100%',
      },
    },
  },
  headerLogo: {
    display: 'flex',
    justifyContent: 'center',
    position: 'relative',
    width: '70%',
    margin: '0 auto',
    paddingTop: '78px',
    '@media (min-width: 1024px)': {
      width: '270px',
    },
    '@media (min-width: 1440px)': {
      width: '340px',
    },
  },
  headerWrapper: {
    position: 'relative',
    '@media (min-width: 1440px)': {
      display: 'flex',
      justifyContent: 'space-between',
      paddingTop: '60px',
    },
  },
  headerTitle: {
    padding: '24px 30px 0 30px',
    boxSizing: 'border-box',
    '@media (min-width: 1440px)': {
      alignSelf: 'flex-end',
      padding: '0 0 160px 64px',
    },
  },
  headerImageContainer: {
    display: 'flex',
    justifyContent: 'center',
    padding: '54px 32px 0 32px',
    '@media (min-width: 1024px)': {
      padding: '66px 93px 0 93px',
    },
    '@media (min-width: 1440px)': {
      width: '54%',
      padding: 0,
    },
  },
  headerImageBorder: {
    boxSizing: 'border-box',
    width: '100%',
    padding: '16px',
    backgroundColor: '#FFF',
    '& img': {
      width: '100%',
      verticalAlign: 'top',
    },
    '@media (min-width: 1024px)': {
      padding: '34px',
    },
    '@media (min-width: 1440px)': {
      padding: '24px 0 24px 24px',
    },
  },
  section: {},
  centered: {
    padding: '0 32px 0 32px',
    '@media (min-width: 1024px)': {
      padding: '0 127px 0 127px',
    },
    '@media (min-width: 1440px)': {
      maxWidth: '800px',
      margin: '0 auto',
      padding: 0,
    },
  },

  wide: {
    '@media (min-width: 1440px)': {
      maxWidth: '1078px',
      margin: '0 auto',
      padding: 0,
    },
  },
  topTextContainer: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    paddingTop: '44px',
    '@media (min-width: 1024px)': {
      paddingTop: '78px',
    },
    '@media (min-width: 1440px)': {
      maxWidth: '840px',
      paddingTop: '90px',
    },
  },
  topText: {
    ...theme.typography.sizeS,
    color: theme.colors.darkBlue,
    '@media (min-width: 1024px)': {
      fontSize: '17px',
      lineHeight: '21px',
    },
    '@media (min-width: 1440px)': {
      fontSize: '19px',
      letterSpacing: '0.02px',
      lineHeight: '23px',
    },
  },
  intro: {
    paddingTop: '44px',
  },
  introContent: {
    position: 'relative',
    height: '600px',
    overflow: 'hidden',
    '& img': {
      width: 'auto',
      verticalAlign: 'top',
    },
  },
  introOverlayContainer: {
    position: 'absolute',
    top: '50%',
    transform: 'translateY(-50%)',
    boxSizing: 'border-box',
    width: '100%',
    padding: '0',
    '@media (min-width: 1024px)': {
      padding: '0 127px',
    },
  },
  introLabel: {
    textAlign: 'center',
    fontSize: '24px',
    fontWeight: 400,
    lineHeight: '46px',
    letterSpacing: '0.3px',
    textTransform: 'uppercase',
    color: theme.colors.darkerBlue,
    '@media (min-width: 1024px)': {
      fontSize: '48px',
      lineHeight: 1,
    },
    '@media (min-width: 1440px)': {
      fontSize: '65px',
      letterSpacing: '0.04px',
    },
  },
  introText: {
    paddingTop: '16px',
    margin: 0,
    padding: '16px 32px 0 32px',
    textAlign: 'center',
    fontSize: '15px',
    fontWeight: 400,
    lineHeight: '19px',
    letterSpacing: '0.01px',
    color: theme.colors.darkBlue,
    '@media (min-width: 1024px)': {
      paddingTop: '24px',
      fontSize: '17px',
      lineHeight: '21px',
    },
    '@media (min-width: 1440px)': {
      maxWidth: '800px',
      margin: '0 auto',
      paddingTop: '55px',
      fontSize: '19px',
      letterSpacing: '0.02px',
      lineHeight: '23px',
    },
  },
  provenance: {},
  provenanceDescription: {
    paddingTop: '44px',
    '@media (min-width: 1024px)': {
      paddingTop: '78px',
    },
  },
  provenanceDescriptionText: {
    ...theme.typography.sizeS,
    color: theme.colors.darkBlue,
    '@media (min-width: 1024px)': {
      fontSize: '17px',
      lineHeight: '21px',
    },
    '@media (min-width: 1440px)': {
      fontSize: '19px',
      letterSpacing: '0.02px',
      lineHeight: '23px',
    },
  },
  video: {
    paddingTop: '44px',
    '@media (min-width: 1024px)': {
      paddingTop: '78px',
    },
    '@media (min-width: 1440px)': {
      paddingTop: '100px',
    },
  },
  diamondImage: {
    paddingTop: '44px',
    '@media (min-width: 1024px)': {
      paddingTop: '78px',
    },
    '@media (min-width: 1440px)': {
      paddingTop: '90px',
    },
  },

  diamondInfo: {
    paddingTop: '44px',
    '@media (min-width: 1024px)': {
      paddingTop: '78px',
    },
    '@media (min-width: 1440px)': {
      paddingTop: '120px',
    },
  },
  diamondInfoLabel: {
    margin: 0,
    textAlign: 'center',
    ...theme.typography.sizeXS,
    fontFamily: 'Futura',
    letterSpacing: '0.01px',
    color: '#001E42',
    '@media (min-width: 1024px)': {
      fontSize: '17px',
    },
    '@media (min-width: 1440px)': {
      fontSize: '19px',
      letterSpacing: '0.02px',
    },
  },
  diamondInfoTitle: {
    paddingTop: '12px',
    '@media (min-width: 1440px)': {
      paddingTop: '18px',
    },
  },
  diamondInfoScanContainer: {
    paddingTop: '80px',
    '@media (min-width: 1024px)': {
      maxWidth: '660px',
      margin: '0 auto',
      paddingTop: '120px',
    },
    '@media (min-width: 1440px)': {
      maxWidth: '660px',
      margin: '0 auto',
    },
  },
  diamondInfoDimensions: {
    '@media (min-width: 1024px)': {
      display: 'flex',
    },
  },
  dimensionBlock: {
    flexBasis: '50%',
    '& img': {
      width: '100%',
      verticalAlign: 'top',
    },
  },
  leftBlock: {
    '@media (min-width: 1024px)': {
      padding: '0 30px 0 0',
    },
  },
  rightBlock: {
    paddingTop: '40px',
    '@media (min-width: 1024px)': {
      padding: '0 0 0 30px',
    },
  },

  diamondInfoProps: {
    paddingTop: '40px',
    '@media (min-width: 1024px)': {
      paddingTop: '140px',
    },
  },

  footer: {
    paddingTop: '80px',
    '@media (min-width: 1024px)': {
      paddingTop: '100px',
    },
  },
  footerSelected: {
    paddingBottom: '69px',
  },
  footerContent: {
    position: 'relative',
    height: '600px',
    overflow: 'hidden',
    '& img': {
      position: 'absolute',
      verticalAlign: 'top',
    },
    '@media (min-width: 320px)': {
      height: '600px',
      '& img': {
        width: '100%',
        height: '100%',
      },
    },
    '@media (min-width: 1024px)': {
      height: '600px',
      '& img': {
        width: '100%',
      },
    },
    '@media (min-width: 1440px)': {
      height: '700px',
    },
  },
  footerOverlayContainer: {
    position: 'absolute',
    top: '50%',
    transform: 'translateY(-50%)',
    boxSizing: 'border-box',
    width: '100%',
    padding: '0 32px',
    '@media (min-width: 1024px)': {
      padding: '0 30px',
    },
  },
  footerOverlayLabel: {
    textAlign: 'center',
    ...theme.typography.sizeXL,
    letterSpacing: '0.04px',
    lineHeight: '40px',
    color: theme.colors.white,
    '@media (min-width: 1024px)': {
      fontSize: '60px',
      lineHeight: '157.4%',
    },
    '@media (min-width: 1440px)': {
      fontSize: '72px',
    },
  },
  footerOverlayText: {
    paddingTop: '24px',
    textAlign: 'center',
    ...theme.typography.sizeS,
    color: theme.colors.white,
    '@media (min-width: 1024px)': {
      maxWidth: '680px',
      margin: '0 auto',
      fontSize: '17px',
      lineHeight: '21px',
    },
    '@media (min-width: 1440px)': {
      maxWidth: '900px',
      fontSize: '19px',
      letterSpacing: '0.02px',
      lineHeight: '23px',
    },
  },
  footerOverlayLink: {
    display: 'flex',
    justifyContent: 'center',
    paddingTop: '44px',
    ...theme.typography.sizeXL,
    fontFamily: 'Futura',
    fontSize: '15px',
    fontWeight: 400,
    letterSpacing: '0.01px',
    textTransform: 'uppercase',
    textDecoration: 'none',
    color: theme.colors.white,
    '@media (min-width: 1024px)': {
      position: 'absolute',
      bottom: '-80px',
      left: '50%',
      transform: 'translateX(-50%)',
    },
    '@media (min-width: 1440px)': {
      bottom: '-120px',
      fontSize: '19px',
      letterSpacing: '0.02px',
    },
  },
}))
