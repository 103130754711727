import { EMAILTEMPLATE_ACTION_TYPES } from '../actionTypes'

const initialState = {
  emailTemplate: {},
}

const emailTemplateReducer = (state = initialState, { type, payload }) => {
  switch (type) {
    case EMAILTEMPLATE_ACTION_TYPES.SET_EMAILTEMPLATE: {
      return {
        emailTemplate: payload,
      }
    }
    case EMAILTEMPLATE_ACTION_TYPES.CLEAR_EMAILTEMPLATE: {
      return initialState
    }

    default:
      return state
  }
}

export default emailTemplateReducer
