import React from 'react'
import { Box } from '@material-ui/core'

import DiamondImage from '../DiamondImage'
import styles from './styles'

const DiamondProvenance = ({ diamond, image, lang, translation }) => {
  const classes = styles()
  const copy = translation.diamondExperience

  return (
    <Box className={classes.diamondProvenanceContainer}>
      <Box className={classes.provenanceContent}>
        <DiamondImage diamondId={diamond.tracrId} imageProps={image} />

        <Box className={classes.provenanceTitleContainer}>
          <Box className={classes.provenanceTitleLabel}>{copy.responsibly}</Box>
          <Box component="h3" className={classes.provenanceTitleCountry}>
            {diamond?.provenance?.provenance.name}
          </Box>
        </Box>
      </Box>
    </Box>
  )
}

export default DiamondProvenance
