import makeStyles from '@material-ui/core/styles/makeStyles'

export default makeStyles((theme) => ({
  address: {
    maxWidth: '312px',
    margin: '32px',
    '@media (max-width: 640px)': {
      maxWidth: '100%',
    },
  },
}))
