import axios from 'axios'
import env from '@beam-australia/react-env'

class Http {
  constructor() {
    this.API_URL = env('API_URL') || 'http://localhost:1337'
  }

  setAuthorization(value) {
    if (!value) {
      delete axios.defaults.headers.common.Authorization
      return
    }

    axios.defaults.headers.common.Authorization = `Bearer ${value}`
  }

  validateBody(body, format) {
    // let all formats other than json be handled by axios
    if ((format && format !== 'json') || !body) return body

    const parsed = JSON.stringify(body)

    // do not send empty data to BE
    if (parsed.length === 0 || parsed === '{}' || parsed === '[]') return undefined

    return body
  }

  get(url, query, isBinary) {
    if (url.split('/')[0] === 'shares') {
      delete axios.defaults.headers.common.Authorization
    }
    if (query && isBinary) {
      return axios.get(`${this.API_URL}/${url}`, {
        params: query,
        ...{ responseType: 'arraybuffer' },
      })
    } else {
      return axios.get(`${this.API_URL}/${url}`)
    }
  }

  post(url, body, format) {
    const validatedBody = this.validateBody(body, format)

    return axios.post(`${this.API_URL}/${url}`, validatedBody)
  }

  put(url, body, format) {
    const validatedBody = this.validateBody(body, format)

    return axios.put(`${this.API_URL}/${url}`, validatedBody)
  }

  delete(url, body, format) {
    const validatedBody = this.validateBody(body, format)

    return axios.delete(`${this.API_URL}/${url}`, validatedBody)
  }
}

const http = new Http()

export default http
