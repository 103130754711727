import API from 'api'
import { USER_ACTION_TYPES as ACTION_TYPES, SESSION_ACTION_TYPES, USER_ACTION_TYPES } from '../actionTypes'

export function loginAction({ username, password }) {
  return (dispatch) => {
    dispatch({ type: ACTION_TYPES.LOGIN.REQUEST })

    return API.login(username, password).then(
      (response) => {
        dispatch({ type: SESSION_ACTION_TYPES.START, payload: response.headers.authorization })
        dispatch({ type: ACTION_TYPES.LOGIN.COMPLETE, payload: response.data.content })
      },
      (error) => {
        dispatch({ type: ACTION_TYPES.LOGIN.FAILURE, payload: error })
      }
    )
  }
}

export function sessionExpiredAction() {
  return (dispatch) => {
    dispatch({ type: USER_ACTION_TYPES.LOGOUT.COMPLETE })
    dispatch({ type: SESSION_ACTION_TYPES.END })
  }
}

export function logoutAction() {
  return (dispatch) => {
    dispatch({ type: ACTION_TYPES.LOGOUT.REQUEST })
    dispatch({ type: SESSION_ACTION_TYPES.END })

    return API.logout().then(
      () => {
        dispatch({ type: ACTION_TYPES.LOGOUT.COMPLETE })
      },
      (error) => {
        dispatch({ type: ACTION_TYPES.LOGOUT.FAILURE, payload: error })
      }
    )
  }
}

export function loadUserDataAction() {
  return (dispatch) => {
    dispatch({ type: ACTION_TYPES.LOAD_USER.REQUEST })

    return API.loadUserData().then(
      (response) => {
        dispatch({ type: ACTION_TYPES.LOAD_USER.COMPLETE, payload: response.data.content })
      },
      (error) => {
        if (error.response && error.response.status === 401) {
          dispatch(sessionExpiredAction())
        } else {
          dispatch({ type: ACTION_TYPES.LOAD_USER.FAILURE, payload: error })
        }
      }
    )
  }
}
