import { STORE_INIT, INTRODUCTION_ACTION_TYPES } from '../actionTypes'

const initialState = {
  introduction: {},
}

const introductionReducer = (state = initialState, { type, payload }) => {
  switch (type) {
    case STORE_INIT: {
      return state
    }
    case INTRODUCTION_ACTION_TYPES.SET_INTRODUCTION: {
      return {
        introduction: payload,
      }
    }

    case INTRODUCTION_ACTION_TYPES.CLEAR_INTRODUCTION: {
      return initialState
    }

    default:
      return state
  }
}

export default introductionReducer
