import CardList from 'containers/CardView/CardList'
import toCard, { getAction } from 'containers/CardView/utils'
import PageButtons from 'containers/PageButtons'
import GTM_Event_TYPES from 'GTM/model'
import { deployGTMDataLayer } from 'GTM/service'
import useLanguage from 'locale'
import React from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useHistory } from 'react-router'
import { selectedDeselectDiamond, selectedSelectDiamond } from 'store/actions'
import { selectDiamonds, selectDiamondsLoading, selectSelectedLanguage } from 'store/selectors'

const CardView = ({ editing, selectedDiamonds }) => {
  const diamonds = useSelector(selectDiamonds)
  const isLoading = useSelector(selectDiamondsLoading)
  const selectedLanguage = useSelector(selectSelectedLanguage)

  const translations = useLanguage()
  const dispatch = useDispatch()
  const history = useHistory()
  const chosenLanguage = selectedLanguage.replace(/_/, '-')

  const handleCardClick = (dbMarque) => {
    const chosenDiamond = diamonds.find((diamond) => diamond.dbMarque === dbMarque)

    deployGTMDataLayer({
      event: GTM_Event_TYPES.DIAMOND_SPECIFICS,
      dbMarque: chosenDiamond.dbMarque,
      provenance: chosenDiamond.provenance.provenance.name,
      caratWeight: chosenDiamond.caratWeight,
      colour: chosenDiamond.colour,
      clarity: chosenDiamond.clarity,
      cut: chosenDiamond.cut,
      availability: chosenDiamond.isSold ? 'sold' : 'available',
    })

    history.push({
      pathname: `/${chosenLanguage}/diamond/${dbMarque}`,
      state: { diamond: null },
    })
    window.scrollTo(0, 0)
  }

  const handleContinue = () => {
    const dbMarqueArr = []
    selectedDiamonds.forEach((selectedDbMarque) => {
      const diamondIndex = diamonds.findIndex((eachDiamond) => eachDiamond.dbMarque === selectedDbMarque)
      dbMarqueArr.push(diamonds[diamondIndex].dbMarque)
    })
    history.push({
      pathname: `/${chosenLanguage}/share`,
      state: { dbMarques: dbMarqueArr },
    })
  }

  const handleSelect = (dbMarque) => {
    // when adddiamond button is clicked
    const addedDiamond = diamonds.find((diamond) => diamond.dbMarque === dbMarque)

    deployGTMDataLayer({
      event: GTM_Event_TYPES.ADD_DIAMOND,
      dbMarque: addedDiamond.dbMarque,
      provenance: addedDiamond.provenance.provenance.name,
      caratWeight: addedDiamond.caratWeight,
      colour: addedDiamond.colour,
      clarity: addedDiamond.clarity,
      cut: addedDiamond.cut,
      availability: addedDiamond.isSold ? 'sold' : 'available',
    })

    dispatch(selectedSelectDiamond({ dbMarque, diamonds }))
  }
  const handleDeselect = (dbMarque) => {
    // when remove diamond button is clicked
    const removedDiamond = diamonds.find((diamond) => diamond.dbMarque === dbMarque)
    deployGTMDataLayer({
      event: GTM_Event_TYPES.REMOVE_DIAMOND,
      dbMarque: removedDiamond.dbMarque,
      provenance: removedDiamond.provenance.provenance.name,
      caratWeight: removedDiamond.caratWeight,
      colour: removedDiamond.colour,
      clarity: removedDiamond.clarity,
      cut: removedDiamond.cut,
      availability: removedDiamond.isSold ? 'sold' : 'available',
    })

    dispatch(selectedDeselectDiamond({ dbMarque, diamonds }))
  }

  const sortDiamonds = (firstDiamond, secondDiamond) => secondDiamond.caratWeight - firstDiamond.caratWeight

  if (isLoading) {
    return null
  }

  const imageSize = {
    width: 220,
    height: 220,
  }
  const cards = diamonds
    .sort(sortDiamonds)
    .map(toCard(translations))
    .map((card) => ({
      ...card,
      selected: !!selectedDiamonds.find((x) => x === card.dbMarque),
      actions: [
        getAction(
          card.dbMarque,
          card.dbMarque,
          !!selectedDiamonds.find((x) => x === card.dbMarque),
          editing,
          handleSelect,
          handleDeselect,
          translations
        ),
      ],
    }))

  return (
    <>
      <CardList key={'card-list'} cards={cards} imageSize={imageSize} onCardClick={handleCardClick} />
      <PageButtons handleContinue={handleContinue} />
    </>
  )
}

export default CardView
