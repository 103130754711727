import makeStyles from '@material-ui/core/styles/makeStyles'

export default makeStyles((theme) => ({
  container: {
    display: 'flex',
    zIndex: 1,
    position: 'fixed',
    bottom: 0,

    width: '100%',
    background: 'rgba(0, 0, 0, 0.9)',
  },
  left: {
    height: 'inherit',
    background: 'rgba(0, 0, 0, 0.6)',
    padding: '18px 32px',

    display: 'flex',
    alignItems: 'center',
  },
  right: {
    textAlign: 'center',
    cursor: 'default',
    color: '#fff',
    fontSize: '18px',
    fontFamily: 'Montserrat',
    fontWeight: 500,
    width: '100%',
    padding: '22px 24px',
    '& span': {
      fontWeight: 600,
    },
  },
}))
