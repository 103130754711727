import PageButtons from 'containers/PageButtons'
import GTM_Event_TYPES from 'GTM/model'
import { deployGTMDataLayer } from 'GTM/service'
import useLanguage from 'locale'
import React from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useHistory } from 'react-router'
import { selectedDeselectDiamond, selectedSelectDiamond } from 'store/actions'
import {
  selectDiamondById,
  selectDiamonds,
  selectIsDiamondSelectedById,
  selectSelectedDiamonds,
  selectSelectedLanguage,
} from 'store/selectors'
import { enhancedUrl } from 'utils/helper'
import DiamondPageComponent from './DiamondPage'

const DiamondPage = ({ location }) => {
  const dispatch = useDispatch()
  const history = useHistory()
  const translation = useLanguage()
  const diamonds = useSelector(selectDiamonds)
  const locationSplitArr = location.pathname.split('/')
  const dbMarque = locationSplitArr[3]
  const diamondFromStore = useSelector(selectDiamondById(dbMarque))

  const isSelected = useSelector(selectIsDiamondSelectedById(dbMarque))

  const selectedDiamonds = useSelector(selectSelectedDiamonds)
  const selectedLanguage = useSelector(selectSelectedLanguage)

  const onDiamondSelect = () => {
    const chosenDiamond = diamonds.find((diamond) => diamond.dbMarque === dbMarque)
    const diamondDetail = {
      dbMarque: chosenDiamond.dbMarque,
      provenance: chosenDiamond.provenance.provenance.name,
      caratWeight: chosenDiamond.caratWeight,
      colour: chosenDiamond.colour,
      clarity: chosenDiamond.clarity,
      availability: chosenDiamond.isSold ? 'sold' : 'available',
    }
    if (isSelected) {
      // diamond is already selected.
      dispatch(selectedDeselectDiamond({ dbMarque: diamondFromStore.dbMarque }))
      deployGTMDataLayer({
        event: GTM_Event_TYPES.CANCEL_SELECTION,
        ...diamondDetail,
      })
    } else {
      dispatch(selectedSelectDiamond({ dbMarque: diamondFromStore.dbMarque, diamonds }))
      deployGTMDataLayer({
        event: GTM_Event_TYPES.SELECT_DIAMOND,
        ...diamondDetail,
      })
    }
  }

  const handleContinue = () => {
    const chosenLanguage = enhancedUrl(selectedLanguage)
    history.push({
      pathname: `/${chosenLanguage}/share`,
      state: { dbMarques: selectedDiamonds },
    })
  }

  if (diamonds.length > 0 && !diamondFromStore) {
    history.push('/')
  }

  return (
    <>
      {diamondFromStore && (
        <DiamondPageComponent
          diamond={diamondFromStore}
          diamonds={location.search.includes('?debug=true') ? diamonds : []} // TODO: remove
          onSelect={onDiamondSelect}
          isSelected={isSelected}
          lang={selectedLanguage}
          translation={translation}
        />
      )}
      <PageButtons dbMarque={dbMarque} handleContinue={handleContinue} />
    </>
  )
}

export default DiamondPage
