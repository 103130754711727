import makeStyles from '@material-ui/core/styles/makeStyles'

export default makeStyles(() => ({
  container: {
    boxShadow: '0px 4px 8px rgba(0, 0, 0, 0.1)',
  },
  content: {
    background: '#ffffff',
    color: '#000000',
    cursor: 'pointer',
    padding: '24px',
  },
  selected: {
    outline: '1px solid #0D1B40',
  },
  actions: {},
}))
