import React, { useEffect } from 'react'
import { Grid } from '@material-ui/core'
import { ReactComponent as BackSVG } from 'assets/icons/back.svg'
import TracrLogo from 'assets/tracr-logo.png'
import Banner from 'components/Banner'
import { ForgotPassword, Login, ResetPassword } from 'pages/authentication'
import DiamondPage from 'pages/diamond'
import List from 'pages/list'
import Share from 'pages/share'
import Success from 'pages/success'
import Welcome from 'pages/welcome'
import Recipient from 'pages/recipient'
import { useSelector, useDispatch } from 'react-redux'
import { Redirect, Route, Switch, useHistory } from 'react-router-dom'
import { selectSession, selectSelectedLanguage } from 'store/selectors'
import { isBearerTokenValid, enhancedUrl, getCorrectUrlLanguage } from 'utils/helper'
import styles from './styles'
import {
  sessionEndAction,
  clearDiamondAction,
  clearSelectedDiamondAction,
  getFooterAction,
  getIntroductionAction,
  getEmailTemplateAction,
  getDiamondsAction,
  clearLanguageAction,
  clearEmailTemplateAction,
  clearFooterAction,
  clearIntroductionAction,
} from 'store/actions'

const dbjProtectedRoutes = [
  {
    path: '/:lang/list',
    component: List,
    name: 'list',
    exact: true,
  },
  {
    path: '/:lang/share',
    component: Share,
    name: 'share',
    exact: true,
  },
  {
    path: '/:lang/diamond/:id',
    component: DiamondPage,
    name: 'diamondpage',
    exact: false,
  },
  {
    path: '/:lang/success',
    component: Success,
    exact: true,
  },
]

const dbjUnprotectedRoutes = [
  {
    path: '/login',
    component: <Login />,
    name: 'login',
    exact: true,
    showBackButton: true,
  },
  {
    path: '/forgot-password',
    component: <ForgotPassword />,
    name: 'forgot-password',
    exact: true,
    showBackButton: true,
  },
  {
    path: '/reset-password',
    component: <ResetPassword />,
    name: 'reset-password',
    exact: true,
    showBackButton: false,
  },
  {
    path: '/welcome',
    component: <Welcome />,
    name: 'welcome',
    exact: true,
    showBackButton: false,
  },
  {
    path: '/',
    component: <Welcome />,
    name: 'welcome',
    exact: true,
  },
  {
    path: '*',
    component: <Welcome />,
    name: 'welcome',
    exact: true,
  },
]

const UnProtectedRouter = ({ path, exact, bearerToken, ...props }) => {
  const classes = styles()
  const history = useHistory()
  let chosenLanguage = useSelector(selectSelectedLanguage)
  chosenLanguage = chosenLanguage.replace(/_/, '-')
  const onBackBtnClick = () => {
    history.push('/')
  }

  if (bearerToken) return <Redirect to={{ pathname: `/${chosenLanguage}/list` }} />

  if (path === '*') return <Redirect to="/" />

  return (
    <div className={classes.container}>
      {props.showBackButton ? (
        <Grid item onClick={onBackBtnClick} className={classes.actionable}>
          <BackSVG />
        </Grid>
      ) : (
        <Grid item />
      )}
      <div className={classes.item}>
        <Banner padding={0} marginTop={38} />
      </div>
      <Route path={path} exact={exact}>
        {props.children}
      </Route>
      <div className={classes.tritem}>
        <img src={TracrLogo} alt="Tracr" />
      </div>
    </div>
  )
}

const ProtectedRouter = ({ path, exact, bearerToken, ...props }) => {
  const dispatch = useDispatch()
  const history = useHistory()
  const chosenLanguage = useSelector(selectSelectedLanguage)
  // bearer token check necessary to perform logout functionality
  if (bearerToken && !isBearerTokenValid(bearerToken)) {
    dispatch(sessionEndAction())
    dispatch(clearLanguageAction())
    dispatch(clearDiamondAction())
    dispatch(clearSelectedDiamondAction())
    dispatch(clearEmailTemplateAction())
    dispatch(clearFooterAction())
    dispatch(clearIntroductionAction())
    return <Redirect to="/" />
  }

  if (!['en-GB', 'zh-CN', 'zh-Hant'].includes(props.location.pathname.split('/')[1])) {
    const pathWithCorrectLang = getCorrectUrlLanguage(props.location)
    history.push(pathWithCorrectLang)
  }

  return (
    <Route
      path={path}
      exact={exact}
      render={({ location }) => {
        if (bearerToken) return <props.children location={location} />
        return <Redirect to={{ pathname: `/${chosenLanguage}`, state: { from: location } }} />
      }}
    />
  )
}

const AppRouter = () => {
  const history = useHistory()
  const session = useSelector(selectSession)
  const dispatch = useDispatch()
  const chosenLanguage = useSelector(selectSelectedLanguage)
  const hyphanetedLang = enhancedUrl(chosenLanguage)

  useEffect(() => {
    if (session) {
      if (history.location.pathname.split('/')[2] === 'shared') return
      dispatch(getDiamondsAction(hyphanetedLang))
      dispatch(getEmailTemplateAction(hyphanetedLang))
      dispatch(getFooterAction(hyphanetedLang))
      dispatch(getIntroductionAction(hyphanetedLang))
    }
  }, [dispatch, session, history.location.pathname, hyphanetedLang])

  return (
    <Switch>
      <Route path="/:lang/shared/:id/:pos?" render={(props) => <Recipient match={props.match} />} />
      {dbjProtectedRoutes.map((eachRoute, index) => (
        <ProtectedRouter key={index} path={eachRoute.path} exact={eachRoute.exact} bearerToken={session}>
          {eachRoute.component}
        </ProtectedRouter>
      ))}
      {dbjUnprotectedRoutes.map((eachRoute, index) => (
        <UnProtectedRouter
          key={index}
          bearerToken={session}
          path={eachRoute.path}
          exact={eachRoute.exact}
          showBackButton={eachRoute.showBackButton}
        >
          {eachRoute.component}
        </UnProtectedRouter>
      ))}
    </Switch>
  )
}

export default AppRouter
