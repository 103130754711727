import { combineReducers } from 'redux'
import { connectRouter } from 'connected-react-router'

import userReducer from './user'
import sessionReducer from './session'
import selectedReducer from './selected'
import diamondsReducer from './diamonds'
import locationReducer from './location'
import footerReducer from './footer'
import introductionReducer from './introduction'
import emailTemplateReducer from './emailTemplate'

const rootReducer = (history) =>
  combineReducers({
    user: userReducer,
    router: connectRouter(history),
    session: sessionReducer,
    selected: selectedReducer,
    diamonds: diamondsReducer,
    location: locationReducer,
    footer: footerReducer,
    introduction: introductionReducer,
    emailTemplate: emailTemplateReducer,
  })

export default rootReducer
