import { Box } from '@material-ui/core'
import testIds from 'config/testIds'
import React from 'react'
import styles from './styles'
import Viewer3D from './Viewer3D'

const DiamondScan = ({ scanProps }) => {
  const classes = styles()

  return (
    <Box className={classes.diamondScanContainer} data-test-id={testIds.diamond.scan}>
      {scanProps && scanProps.url && <Viewer3D assetUrl={scanProps.url} item={scanProps} />}
    </Box>
  )
}

export default DiamondScan
