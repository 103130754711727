import React from 'react'
import PropTypes from 'prop-types'
import Grid from '@material-ui/core/Grid'
import classNames from 'classnames'

import DiamondImage from 'pages/diamond/DiamondImage'

import styles from './styles'

const CardMiddle = ({ media, imageSize }) => {
  const classes = styles({ imageSize })
  return (
    <Grid container style={{ height: 'auto' }}>
      <Grid item className={classes.container}>
        <div className={classes.media}>
          <div
            className={classNames({
              [classes.mediaInner]: true,
            })}
          >
            <DiamondImage {...media} />
          </div>
        </div>
      </Grid>
    </Grid>
  )
}

CardMiddle.propTypes = {
  imageSize: PropTypes.shape({
    width: PropTypes.number,
    height: PropTypes.number,
  }).isRequired,
  media: PropTypes.object.isRequired,
}

export default CardMiddle
