import makeStyles from '@material-ui/core/styles/makeStyles'

export default makeStyles((theme) => {
  return {
    container: {
      position: 'fixed',
      zIndex: 1,
      top: 0,
      backgroundColor: theme.colors.white,
      height: 69,
      boxShadow: theme.shadows[0],
      padding: '0 0 0 8px',
      fontFamily: 'Montserrat',
      fontWeight: 500,
      textTransform: 'uppercase',
      letterSpacing: '3px',
    },
    actionable: {
      cursor: 'pointer',
    },
    textLogo: {
      padding: '0 8px 0 8px',
      '@media (min-width: 1024px)': {
        position: 'absolute',
        left: '50%',
        transform: 'translateX(-50%)',
        padding: 0,
      },
    },
    linkContainer: {
      height: '100%',
      display: 'flex',
      alignItems: 'center',
    },
    link: {
      fontFamily: 'Montserrat',
      textDecoration: 'none',
      display: 'block',
      padding: '14px 32px',
      textAlign: 'center',
      color: 'rgb(13, 27, 64, 0.2)',
      cursor: 'default',
      '&:hover': {
        textDecoration: 'none',
      },
    },
    activeLink: {
      cursor: 'pointer',
      background: '#0D1B40',
      color: '#ffffff',
    },
    diamonPageStyle: {
      background: theme.colors.white,
      '& a': {
        background: theme.colors.white,
        color: theme.colors.darkerBlue,
      },
    },
    diamonPageStyleActive: {
      background: theme.colors.darkerBlue,
      '& a': {
        background: theme.colors.darkerBlue,
        color: theme.colors.white,
      },
    },
  }
})
