import { Box } from '@material-ui/core'
import GTM_Event_TYPES from 'GTM/model'
import { deployGTMDataLayer } from 'GTM/service'
import React, { useRef } from 'react'
import styles from './styles'

const DiamondVideo = ({ videoProps }) => {
  const videoElement = useRef(null)
  const classes = styles()

  const onVideoPlay = () => {
    deployGTMDataLayer({
      event: GTM_Event_TYPES.VIDEO,
      dbMarque: videoProps.dbMarque,
      provenance: videoProps.provenance.provenance.name,
      caratWeight: videoProps.caratWeight,
      colour: videoProps.colour,
      clarity: videoProps.clarity,
      cut: videoProps.cut,
    })
  }

  return (
    <Box className={classes.diamondVideoContainer}>
      <Box
        component="video"
        ref={videoElement}
        onPlay={onVideoPlay}
        className={classes.video}
        controls
        controlsList="nodownload"
        disablePictureInPicture
      >
        <source src={videoProps.video.url} type={`video/mp4`} alt={videoProps.video.alternativeText} />
      </Box>
    </Box>
  )
}

export default DiamondVideo
