import routes from './routes'
import { theme } from './theme'
import featureToggles from './featureToggles'

export const config = {
  routes,
  theme,
  featureToggles,
}

export { routes, theme, featureToggles }
