import { SESSION_ACTION_TYPES } from '../actionTypes'

export function sessionStartAction(payload) {
  return {
    type: SESSION_ACTION_TYPES.START,
    payload,
  }
}

export function sessionEndAction() {
  return {
    type: SESSION_ACTION_TYPES.END,
  }
}

export function setShareIdAction(payload) {
  return {
    type: SESSION_ACTION_TYPES.SET_SHARE_ID,
    payload,
  }
}
