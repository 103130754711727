import to2Digets from 'utils/to2Digets'
import useLanguage from 'locale'

const usePropsMapper = () => {
  const translation = useLanguage()
  const copy = translation.diamondExperience.props

  const fieldsMap = {
    symmetry: {
      EX: 'Excellent',
    },
    fluorescence: {
      N: 'None',
    },
    quality: {
      EX: 'Excellent',
    },
  }

  return {
    left: [
      {
        key: 'measurements',
        label: copy.measurements,
        value: (diamond) =>
          `${to2Digets(diamond.lengthInMillimeters)} x ${to2Digets(diamond.widthInMillimeters)} x ${to2Digets(
            diamond.depthInMillimeters
          )} mm`,
      },
      {
        key: 'diamond.cut',
        label: copy.cut,
        value: (diamond) => diamond.cut,
      },
      {
        key: 'diamond.caratWeight',
        label: copy.carats,
        value: (diamond) => `${to2Digets(diamond.caratWeight)}${translation.diamondExperience.props.caratsShort}`,
      },
      {
        key: 'diamond.colour',
        label: copy.colour,
      },
      {
        key: 'diamond.clarity',
        label: copy.clarity,
      },
      {
        key: 'diamond.quality',
        label: copy.quality,
        value: (diamond) => fieldsMap.quality[diamond.quality] || diamond.quality,
      },
      {
        key: 'diamond.symmetry',
        label: copy.symmetry,
        value: (diamond) => fieldsMap.symmetry[diamond.symmetry] || diamond.symmetry,
      },
      {
        key: 'fluorescence',
        label: copy.fluorescence,
        value: (diamond) => fieldsMap.fluorescence[diamond.fluorescence] || diamond.fluorescence,
      },
    ],
    right: [
      {
        key: 'tracrId',
        label: copy.tracr_id,
      },
      {
        key: 'dbMarque',
        label: copy.db_marque,
        value: (diamond) => diamond.dbMarque,
      },
      {
        key: 'dbGis',
        label: copy.db_gis,
        value: (diamond) => diamond.dbGis,
      },
    ],
  }
}

export default usePropsMapper
