import { Grid } from '@material-ui/core'
import Dropdown from 'components/Dropdown'
import GTM_Event_TYPES from 'GTM/model'
import { deployGTMDataLayer } from 'GTM/service'
import useLanguage from 'locale'
import React, { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useHistory } from 'react-router-dom'
import { setLanguageAction } from 'store/actions'
import { selectLanguages, selectSelectedLanguage } from 'store/selectors'
import { enhancedUrl } from 'utils/helper'
import makeStyles from './styles'

const LanguageSelector = () => {
  const classes = makeStyles()
  const dispatch = useDispatch()
  const currentSelectedLang = useSelector(selectSelectedLanguage)
  const translations = useLanguage()
  const languages = useSelector(selectLanguages)

  const languageMap = {
    en_GB: 'English',
    zh_CN: '简体中文',
    zh_Hant: '繁體中文',
  }

  const options = languages.map((lang) => ({ value: lang, title: languageMap[lang] }))
  const history = useHistory()

  const onLanguageChange = (lang) => {
    const enhancedLang = enhancedUrl(lang)
    const newPath = `/${enhancedLang}/${history.location.pathname.split('/')[2]}`
    history.push(newPath)
    dispatch(setLanguageAction(lang))

    deployGTMDataLayer({
      event: GTM_Event_TYPES.LANGUAGE_SELECTION,
      selectedLanguage: enhancedLang,
    })
  }

  useEffect(() => {
    document.title = translations.navigation.pageTitle
  }, [translations])

  return (
    <Grid container justify="center" className={classes.container}>
      <Dropdown options={options} onChange={onLanguageChange} selected={currentSelectedLang} />
    </Grid>
  )
}

export default LanguageSelector
