const testIds = {
  address: {
    addressContainer: 'address.addressContainer',
    phone: 'address.phone',
    city: 'address.city',
    email: 'address.email',
    line: 'address.line',
  },
  header: {
    container: 'headerContainer',
    backButton: 'headerBackButton',
    logoutButton: 'headerLogoutButton',
    tracrLink: 'headerTracrLink',
    masterLink: 'headerMasterLink',
    textLogo: 'headerTextLogo',
    selectButton: 'headerSelectButton',
  },
  diamond: {
    props: {
      measurements: 'diamondPropsMeasurements',
      'polished.shape': 'diamondPropsPolishedShape',
      'polished.carats': 'diamondPropsPolishedCarats',
      'polished.colour': 'diamondPropsPolishedColour',
      'polished.clarity': 'diamondPropsPolishedClarity',
      'polished.polish_quality': 'diamondPropsPolishedQuality',
      'polished.symmetry': 'diamondPropsPolishedSymmetry',
      tracr_id: 'diamondPropsTracrId',
      db_marque: 'diamondPropsDbMarque',
      db_gis: 'diamondPropsDbGis',
      fluorescence: 'diamondPropsFluorescence',
    },
    container: 'diamondContainer',
    header: 'diamondHeader',
    topText: 'diamondTopText',
    intro: 'diamondIntro',
    provenance: 'diamondProvenance',
    provenanceDescription: 'diamondProvenanceDescription',
    video: 'diamondVideo',
    diamondInfo: 'diamondInfo',
    diamondScan: 'diamondScan',
    diamondDimensions: 'diamondDimensions',
    diamondProps: 'diamondProps',
    footer: 'diamondFooter',
    findOutMore: 'diamondFindOutMore',
    headerTitle: 'diamondHeaderTitle',
    infoTitle: 'diamondInfoTitle',
    title: 'diamondTitle',
    scan: 'diamondScan',
    image: 'diamondImage',
    imageRight: 'diamondImageRight',
    imageLeft: 'diamondImageLeft',
    fluorescence: 'diamondFluorescence',
    heart: 'diamondImageHeart',
    arrow: 'diamondImageArrow',
    ideal_scope: 'diamondImageIdealScope',
    aset_scope: 'diamondImageAsetScope',
  },
  cardView: {
    card: 'card',
    cardButton: 'cardButton',
    cardDataPoint: 'cardDataPoint',
    cardFlag: 'cardFlag',
  },
  footer: {
    continueButtom: 'footerContinueButtom',
  },
  share: {
    smsInput: 'shareSmsInput',
    smsLabel: 'shareSmsLabel',
    emailInput: 'shareEmailInput',
    emailLabel: 'shareEmailLabel',
    emailError: 'shareEmailError',
    subjectInput: 'shareSubjectInput',
    subjectLabel: 'shareSubjectLabel',
    messageInput: 'shareMessageInput',
    smsError: 'shareSmsError',
    privacyPolicyLink: 'sharePrivacyPolicyLink',
    headerTitle: 'shareHeaderTitle',
    templateButtonOne: 'shareTemplateButtonOne',
    templateButtonTwo: 'shareTemplateButtonTwo',
    templateButtonThree: 'shareTemplateButtonThree',
  },
  shareUnavailable: {
    title: 'shareUnavailable.title',
    subtitle: 'shareUnavailable.subtitle',
    address1: 'shareUnavailable.address1',
    address2: 'shareUnavailable.address2',
    banner: 'shareUnavailable.banner',
  },
  success: {
    text: 'successText',
    back: 'successBack',
    footerText: 'footerText',
  },
  login: {
    button: 'loginButton',
    banner: 'loginBanner',
    tracrLogo: 'loginTracrLogo',
  },
  'forgot-password': {
    button: 'forgotPasswordButton',
    banner: 'loginBanner',
    tracrLogo: 'loginTracrLogo',
  },
  'reset-password': {
    button: 'resetPasswordButton',
    banner: 'loginBanner',
    tracrLogo: 'loginTracrLogo',
  },
  dropdown: {
    leftIcon: 'dropdown.leftIcon',
    rightIcon: 'dropdown.rightIcon',
    select: 'dropdown.select',
    option: 'dropdown.option',
  },
  warningBar: {
    icon: 'warningBar.icon',
    message: 'warningBar.message',
  },
}

export default testIds
