import React from 'react'
import PropTypes from 'prop-types'

import { ReactComponent as WarningSVG } from 'assets/icons/warning.svg'

import styles from './styles'
import useLanguage from 'locale'
import testIds from 'config/testIds'

const WarningBar = ({ max, currentSelectedCount }) => {
  const classes = styles()
  const translations = useLanguage()
  const needToRemove = Math.abs(parseInt(max, 10) - currentSelectedCount)

  return (
    <div className={classes.container}>
      <div className={classes.left}>
        <WarningSVG width={32} height={32} data-test-id={testIds.warningBar.icon} />
      </div>
      <div
        className={classes.right}
        data-test-id={testIds.warningBar.message}
        dangerouslySetInnerHTML={{ __html: translations.list.shareLimit(max, needToRemove) }}
      />
    </div>
  )
}

WarningBar.propTypes = {
  max: PropTypes.string.isRequired,
  currentSelectedCount: PropTypes.number.isRequired,
}

export default WarningBar
