import { EMAILTEMPLATE_ACTION_TYPES } from '../actionTypes'
import API from 'api'

export function getEmailTemplateAction(lang) {
  return (dispatch) => {
    return API.getEmailTemplate(lang).then(
      (response) => {
        return dispatch({
          type: EMAILTEMPLATE_ACTION_TYPES.SET_EMAILTEMPLATE,
          payload: response.data,
        })
      },
      (error) => {
        console.log('email template fetching error: ', error.message)
      }
    )
  }
}

export function clearEmailTemplateAction() {
  return (dispatch) => {
    dispatch({ type: EMAILTEMPLATE_ACTION_TYPES.CLEAR_EMAILTEMPLATE })
  }
}
