/* eslint-disable */
// Traditional Chinese

const translation = {
  navigation: {
    pageTitle: 'The 1888 Master Diamonds Collection | 探尋絕世美鑽非凡旅程 | De Beers Jewellery',
    items: {
      list: 'List',
      diamond: 'Diamond',
      welcome: 'Welcome',
      login: 'Login',
      'reset-password': 'Reset passowrd',
      'forgot-password': 'Forgot passowrd',
    },
  },
  diamondExperience: {
    headingTitle: 'De Beers The 1888 Master Diamonds 系列',
    masterDiamondsTitle: 'De Beers <br /> The 1888 Master Diamonds 系列',
    masterDiamondsText: `1888 Master Diamonds 以 De Beers 成立的年份命名。這些世上最瑰麗之一的鑽石源自我們的礦區，經悉心挑選後，透過精湛的切割工藝來呈現其最美麗的形態，絕非僅以重量為焦點，成為可佩戴的藝術品。

      我們利用由 De Beers 研發的互聯價值鏈平台 Tracr 提供的嶄新技術，追踪這些鑽石的來源和工藝歷程。這種透明度和可追溯性，代表我們可以確保這些鑽石均以符合道德標準的方式開採，百分之百不涉及衝突，讓顧客更安心，並藉此在生產過程中為社會創造積極影響及長久的社會財富。`,
    responsibly: '以遵循道德章程的方式開採自',
    diamondInformation: '鑽石資訊',
    props: {
      measurements: '尺寸',
      carats: '克拉重量',
      caratsShort: '克拉',
      symmetry: '對稱性',
      cut: '切割',
      clarity: '淨度',
      quality: '拋光',
      colour: '顏色',
      fluorescence: '螢光',
      gia: 'GIA 編碼',
      tracr_id: 'Tracr ID',
      db_marque: 'DB Marque',
      db_gis: 'DBGIS Grading Report Number',
    },
    buildingForeverTitle: '建設永恆',
    buildingForeverText:
      '我們一直致力為社會創造積極影響及長久的社會財富，即使到了最後一顆鑽石被發掘之時，此精神也能繼續傳承下去。這項承諾稱作「建設永恆」（Building Forever）。這項計劃的措施包括支持超過 1,250 名女性微型企業家，實施國家橫跨納米比亞、波札那與南非; 改善在當地社區獲得優質教育的機會，並通過保護生物多樣性，和採取大膽及創新的舉措應對氣候變化及其影響，從而保護大自然。',
    findOutMoreLabel: '瞭解更多',
    findOutMoreLink: 'https://www.debeers.hk/zh-hk/building-forever.html',
    selectDiamond: '選擇鑽石',
    cancelSelection: '取消選擇',
    prev: '上一颗鑽石',
    next: '以前的鑽石',
    diamonds: '鑽石',
  },
  login: {
    button: {
      loginButtonText: '登錄',
    },
    messages: {
      customerView: {
        enterEmail: '輸入您的電子郵件',
        enterPassword: '輸入密碼',
        requestFailure: '登錄失敗,請聯繫系統管理員。',
        invalidEmail: '請提供有效的電子郵件',
        invalidPassword: '請提供有效密碼',
        inCorrectCombination: '郵箱或密碼不正確',
      },
    },
  },
  resetPassword: {
    button: {
      resetPasswordBtnText: '重置密碼',
      okButtonText: '好的',
    },
    messages: {
      customerView: {
        enterNewPassword: '輸入您的新密碼',
        confirmNewPassword: '確認您的新密碼',
        resetDialogTitle: ' 請確保提供的兩個密碼匹配',
        requestFailure: '重置密碼失敗, 請聯繫系統管理員。',
        followInstruction: '請遵循重置密碼的正確指示',
        passwordMismatch: '請確保提供的兩個密碼匹配',
        passwordSuccess: '您已成功重置密碼,請重新登錄。',
      },
    },
  },
  forgetPassword: {
    button: {
      loginInstead: '登錄？',
      submit: '提交',
    },
    messages: {
      customerView: {
        enterEmail: '輸入您的電子郵件',
        requestFailure: '忘記密碼失敗, 請聯繫系統管理員',
        emailError: '請提供有效的電子郵件',
        checkEmail: '請檢查您的電子郵件',
        emailSentMsg: '您應該已經收到一封電子郵件，其中包含有關如何重置密碼的說明。',
      },
    },
  },
  share: {
    fullStop: '。',
    SoldDiamondShareText: '這顆鑽石現已售出, 請聯繫您的品牌大使討論其他替代鑽石。',
    buttons: {
      followUp: '持續聯繫',
      introduction: '簡介',
      masterClass: '專家講座詢問',
    },
    selectedSingular: '已選 1 顆鑽石',
    selectedPlural: (param1) => `已選 ${param1}顆鑽石`,
    editSelection: '編輯選擇',
    smsLimit: '透過簡訊發送的訊息，字數限制為500個字元以內',
    email: '以電子郵件傳送',
    emailError: '請輸入有效的電子郵件信箱',
    to: '收件人',
    toSms: '收件人',
    enterEmailAddress: '請輸入電子郵件地址',
    subject: '主旨',
    enterSubject: '請輸入主旨',
    sms: '簡訊',
    smsInfo: '請輸入包括國家/地區代號的電話號碼',
    smsError: '請輸入有效的電話號碼',
    smsError86: '我們無法分享至 +86 的號碼',
    smsError1: '我們無法分享至 +1 的號碼',
    smsErrorDialCode: '請輸入有效的國際電話區號',
    enterMobile: '輸入手機號碼',
    addMessage: '新增訊息',
    addMessageInfo: '請於此處輸入您的訊息內容',
    shareButton: '分享目前所選的鑽石',
    subjects: {
      subject1: '您的 1888 Master Diamonds 系列鑽石資訊',
      subject2: '關於 1888 Master Diamonds 系列',
      subject3: '感謝您參與 De Beers 鑽石專家線上講座。',
    },
    messages: {
      message1: `親愛的 [client’s name] 女士/先生：

感謝您對 De Beers 1888 Master Diamonds 系列鑽石的興趣。

依照之前的溝通，我們在此提供關於您所選擇的鑽石更為詳細的資訊，提供您參閱。

如有任何問題，我們很樂意隨時為您效勞。

祝好。

[BA’s name]`,
      message2: `親愛的 [client’s name] 女士/先生：

很榮幸與您分享 De Beers 的最新傑作：1888 Master Diamonds 系列。該系列精心甄選 19 顆無與倫比的天然美鑽，為您全新呈獻。

您或許會對其中此款作品感興趣。隨信附上詳細作品介紹，以及關於此系列的更多資訊。

如有任何問題，我們很樂意隨時為您效勞。

祝好。

[BA’s name]`,
      message3: `親愛的 [client’s name] 女士/先生：

感謝您參加本期以 1888 Master Diamonds 系列為主題的鑽石專家線上講座。

我們注意到，您對此款作品有一些疑問。在此特別與您分享相關詳情及系列資訊。

如有任何問題，我們很樂意隨時為您效勞。

祝好。

[BA’s name]`,
    },
    terms: '瞭解更多關於我們如何處理您的個人資料相關資訊，請參閱我們的',
    termsLink: '隱私政策',
    termsUrl: 'https://www.debeers.hk/zh-hk/privacy-policy.html',
  },
  list: {
    title: 'De Beers The 1888 Master Diamonds 系列',
    removeAll: '移除所有鑽石',
    remove: '移除鑽石',
    add: '添加鑽石',
    continue: '以目前所選鑽石繼續進行下一步',
    shareLimit: (param1, param2) =>
      `您一次最多可以添加<span>${param1}</span>顆鑽石。移除<span>${param2}</span>顆鑽石以繼續。`,
  },
  success: {
    shared: '您已成功分享目前所選的鑽石',
    button: '回到鑽石清單',
    assuredBy: '驗證',
  },
  shareUnavailable: {
    headerTitleLong: 'De Beers The 1888 Master Diamonds 系列',
    headerTitleShort: 'De Beers 1888',
    title: '您選擇的鑽石已經不存在',
    subtitle: '請聯繫您的品牌大使了解更多信息',
    deBeersLink: 'https://www.debeers.hk/zh-hk/home',
    masterLink: 'https://www.debeers.hk/zh-hk/the-1888-master-diamonds.html',
  },
}

export default translation
