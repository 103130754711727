import React from 'react'
import { Box } from '@material-ui/core'
import { pathOr } from 'ramda'

import to2Digets from 'utils/to2Digets'
import useLanguage from 'locale'

const Title = ({ diamond, mobile, lang }) => {
  const translations = useLanguage()
  const titleProps = [
    { key: 'cut' },
    { key: 'caratWeight', extension: translations.diamondExperience.props.caratsShort },
    { key: 'colour' },
    { key: 'clarity' },
  ]

  return (
    <>
      {titleProps.map((prop, index) => {
        const value = pathOr('', [`${prop.key}`], diamond)

        if (!value) return null

        const formattedValue = Number.isFinite(value) ? to2Digets(value) : value

        return (
          <React.Fragment key={prop.key}>
            {formattedValue}
            {prop.extension}
            {index !== titleProps.length - 1 && <Box component="span"> / </Box>}
            {/* for chinese on mobile, add a line break after 2nd param */}
            {mobile && lang !== 'en_GB' && index === 1 ? <br /> : null}
          </React.Fragment>
        )
      })}
    </>
  )
}

export default Title
