/* eslint-disable */
// Simplified Chinese

const translation = {
  navigation: {
    pageTitle: 'The 1888 Master Diamonds 大师美钻系列 | 钻石的奇妙旅程 | De Beers 戴比尔斯钻石珠宝',
    items: {
      list: 'List',
      diamond: 'Diamond',
      welcome: 'Welcome',
      login: 'Login',
      'reset-password': 'Reset passowrd',
      'forgot-password': 'Forgot passowrd',
    },
  },
  diamondExperience: {
    headingTitle: '戴比尔斯 1888 Master Diamonds 大师美钻系列',
    masterDiamondsTitle: '1888 Master Diamonds<br /> 大师美钻系列',
    masterDiamondsText: `得名于戴比尔斯的创立之年，1888 Master Diamonds 大师美钻系列甄选一系列无与伦比的天然钻石，尽显稀世之美。钻石甄选自戴比尔斯钻石矿区，以美感为至高标准而非重量最大化进行切割，融合匠心工艺，打造出一件件可佩戴的艺术杰作。

    为了令顾客能安心选购戴比尔斯钻石，我们自主开发了区块链平台 Tracr，运用众多尖端科技，追溯每颗钻石的工艺制作流程。Tracr 令戴比尔斯拥有了直接追溯钻石来源的能力，确保每一颗钻石的采购生产皆符合道德标准，且100% 来自非冲突区域。不仅如此，戴比尔斯钻石在其奇妙旅程中产生的积极影响，也将被记录并呈现。`,
    responsibly: '以遵循道德章程的方式开採自',
    diamondInformation: '钻石信息',
    props: {
      measurements: '尺寸',
      carats: '克拉重量',
      caratsShort: '克拉',
      symmetry: '对称性',
      cut: '切工',
      clarity: '净度',
      quality: '抛光',
      colour: '颜色',
      fluorescence: '荧光',
      gia: 'GIA 编码',
      tracr_id: 'Tracr ID',
      db_marque: '戴比尔斯印记',
      db_gis: 'DBGIS 评级报告编号',
    },
    buildingForeverTitle: '铸就永恒',
    buildingForeverText: `戴比尔斯为创造积极而长久的社会财富所许下的承诺，将和天然钻石一同坚守至永恒。<br />这项承诺称作“铸就永恒”。戴比尔斯至今已为纳米比亚、博茨瓦纳和南非超过 1,250 名女性小微型企业家提供帮助，为所在社区带来更多优质教育资源。我们还通过维护生物多样性来保护自然环境，并大胆采取创新措施，以应对气候变化及其影响。   `,
    findOutMoreLabel: '了解更多',
    findOutMoreLink: 'https://www.debeers.com.cn/zh-cn/building-forever.html',
    selectDiamond: '选择钻石',
    cancelSelection: '取消已选状态',
    prev: '上一颗钻石',
    next: '以前的钻石',
    diamonds: '钻石',
  },
  login: {
    button: {
      loginButtonText: '登录',
    },
    messages: {
      customerView: {
        enterEmail: '输入您的电子邮件',
        enterPassword: '输入密码',
        requestFailure: '登录失败,请联系系统管理员。',
        invalidEmail: '请提供有效的电子邮件',
        invalidPassword: '请提供有效密码',
        inCorrectCombination: '邮箱或密码不正确',
      },
    },
  },
  resetPassword: {
    button: {
      resetPasswordBtnText: '重置密码',
      okButtonText: '好的',
    },
    messages: {
      customerView: {
        enterNewPassword: '输入您的新密码',
        confirmNewPassword: '确认您的新密码',
        resetDialogTitle: '密码重置确认',
        requestFailure: '重置密码失败,请联系系统管理员。',
        followInstruction: '请遵循重置密码的正确指示',
        passwordMismatch: '请确保提供的两个密码匹配',
        passwordSuccess: ' 您已成功重置密码,请重新登录。',
      },
    },
  },
  forgetPassword: {
    button: {
      loginInstead: '登录？',
      submit: '提交',
    },
    messages: {
      customerView: {
        enterEmail: '输入您的电子邮件',
        requestFailure: '忘记密码失败,请联系系统管理员',
        emailError: '请提供有效的电子邮件',
        checkEmail: '请检查您的电子邮件',
        emailSentMsg: '您应该已经收到一封电子邮件，其中包含有关如何重置密码的说明。',
      },
    },
  },
  share: {
    fullStop: '。',
    SoldDiamondShareText: '这颗钻石现已售出，请联系您的品牌大使讨论其他替代钻石。',
    buttons: {
      followUp: '回访',
      introduction: '简介',
      masterClass: '大师课咨询',
    },
    selectedSingular: '已选 1 颗钻石',
    selectedPlural: (param1) => `已选 ${param1} 颗钻石`,
    editSelection: '编辑选择',
    smsLimit: '通过短信发送的信息，限制在 500 个字符以内',
    email: '电子邮件',
    emailError: '请输入有效的电子邮件地址',
    to: '收件人',
    toSms: '收件人',
    enterEmailAddress: '输入电子邮箱地址',
    subject: '主题',
    enterSubject: '输入主题',
    sms: '短信',
    smsInfo: '请输入包括国家/地区代号的电话号码',
    smsError: '请输入有效的电话号码',
    smsError86: '我们无法分享至 +86 的号码',
    smsError1: '我们无法分享至 +1 的号码',
    smsErrorDialCode: '请输入有效的国际电话区号',
    enterMobile: '输入手机号码',
    addMessage: '添加短信',
    addMessageInfo: '请在此处输入短信内容',
    shareButton: '分享您选择的钻石',
    subjects: {
      subject1: '您的 1888 Master Diamonds 大师美钻信息',
      subject2: '关于 1888 Master Diamonds 大师美钻系列',
      subject3: '感谢您参与戴比尔斯线上钻石大师课。',
    },
    messages: {
      message1: `尊敬的 [client’s name] 女士/先生：

感谢您对戴比尔斯 1888 Master Diamonds 大师美钻系列的关注。

依照此前沟通，关于您所选择的钻石，我们在此提供更为详尽的信息，供您参阅。

如有任何问题，我们随时为您效劳。

祝好。

[BA’s name]`,
      message2: `尊敬的 [client’s name] 女士/先生：

很荣幸与您分享戴比尔斯的最新杰作：1888 Master Diamonds 大师美钻系列。该系列精心甄选 19 颗无与伦比的天然美钻，为您全新呈献。

您或许会对其中此款作品感兴趣。随信附上详细作品介绍，以及关于该系列的更多资讯。

如有任何问题，我们随时为您效劳。

祝好。

[BA’s name]`,
      message3: `尊敬的 [client’s name] 女士/先生：

感谢您参加本期以 1888 Master Diamonds 大师美钻系列为主题的线上钻石大师课。

我们注意到，您对此款作品有一些疑问。在此特别与您分享相关详情及系列资讯。

如有任何问题，我们随时为您效劳。

祝好。

[BA’s name]`,
    },
    terms: '了解更多戴比尔斯对个人隐私数据的处理方式，请参阅我们的',
    termsLink: '隐私政策',
    termsUrl: 'https://www.debeers.com.cn/zh-cn/privacy-policy.html',
  },
  list: {
    title: '戴比尔斯 1888 Master Diamonds 大师美钻系列',
    removeAll: '移除所有钻石',
    remove: '移除钻石',
    add: '添加钻石',
    continue: '已选好钻石，进入下一步',
    shareLimit: (param1, param2) =>
      `一次最多可添加 <span>${param1}</span> 颗钻石，进入下一步之前，请移除 <span>${param2}</span> 颗钻石`,
  },
  success: {
    shared: '已成功分享钻石',
    button: '返回钻石展厅',
    assuredBy: '经核实',
  },
  shareUnavailable: {
    headerTitleLong: '戴比尔斯 1888 Master Diamonds 大师美钻系列',
    headerTitleShort: 'De Beers 1888',
    title: '您选择的钻石已经不存在',
    subtitle: '请联系您的品牌大使了解更多信息',
    deBeersLink: 'https://www.debeers.com.cn/zh-cn/home',
    masterLink: 'https://www.debeers.com.cn/zh-cn/the-1888-master-diamonds.html',
  },
}

export default translation
