/* eslint-disable */
const translation = {
  navigation: {
    pageTitle:
      'The 1888 Master Diamonds Collection | Diamond Provenance and Craftsmanship Journey | De Beers Jewellery',
    items: {
      list: 'List',
      diamond: 'Diamond',
      welcome: 'Welcome',
      login: 'Login',
      'reset-password': 'Reset passowrd',
      'forgot-password': 'Forgot passowrd',
    },
  },
  diamondExperience: {
    headingTitle: 'THE 1888 MASTER DIAMONDS BY DE BEERS',
    masterDiamondsTitle: 'THE 1888 MASTER DIAMONDS',
    masterDiamondsText:
      'Named after the year De Beers was founded, our 1888 Master Diamonds are a curated selection of some of \
                the world’s most beautiful diamonds. Sourced from our mines and cut to the highest standard of beauty \
                over weight, these diamonds were transformed into wearable works of art. <br /> \
                <br /> \
                Aiming to provide even greater peace of mind to our clients, we traced these diamonds’ provenance and \
                craftsmanship journey using the leading emerging technologies provided by Tracr, a Connected Value Chain \
                platform developed by De Beers. Having this direct traceability means that we can ensure that all these \
                diamonds have been ethically produced, are 100 per cent conflict-free and have had a positive impact \
                along their journey.',
    responsibly: 'Responsibly and ethically sourced in',
    diamondInformation: 'DIAMOND INFORMATION',
    props: {
      measurements: 'Measurements',
      carats: 'Carat Weight',
      caratsShort: 'cts',
      symmetry: 'Symmetry',
      cut: 'Cut',
      clarity: 'Clarity',
      quality: 'Polish',
      colour: 'Colour',
      fluorescence: 'Fluorescence',
      gia: 'GIA Number',
      tracr_id: 'Tracr ID',
      db_marque: 'DB Marque',
      db_gis: 'DBGIS Grading Report Number',
    },
    buildingForeverTitle: 'BUILDING FOREVER',
    buildingForeverText:
      'Our long-term commitment to creating a positive legacy that will endure well beyond the recovery of our \
    last diamond is called Building Forever, and among other things it supports more than 1,250 women \
    small-scale micro-entrepreneurs across Namibia, Botswana and South Africa, improves access to quality \
    education in our communities and protects the natural world by preserving biodiversity, and by taking \
    bold and innovative steps to address climate change and its effects.',
    findOutMoreLabel: 'Find out more',
    findOutMoreLink: 'https://www.debeers.co.uk/en-gb/building-forever.html',
    selectDiamond: 'Select diamond',
    cancelSelection: 'Cancel Selection',
    prev: 'Previous',
    next: 'Next',
    diamonds: 'Diamonds',
  },
  login: {
    button: {
      loginButtonText: 'Login',
    },
    messages: {
      customerView: {
        enterEmail: 'Enter your email',
        enterPassword: 'Enter your password',
        requestFailure: 'Login Failed. Please contact the system administrator',
        invalidEmail: 'Please provide valid email',
        invalidPassword: 'Please provide valid password',
        inCorrectCombination: 'The combination of email & password is not correct',
      },
    },
  },
  resetPassword: {
    button: {
      resetPasswordBtnText: 'Reset Password',
      okButtonText: 'Ok',
    },
    messages: {
      customerView: {
        enterNewPassword: 'Enter your new password',
        confirmNewPassword: 'Confirm your new password',
        resetDialogTitle: 'Password reset confirmation',
        requestFailure: 'Reset-password Failed. Please contact the system administrator',
        followInstruction: 'Please follow the right instruction of reset password',
        passwordMismatch: 'Please make sure that both provided passwords are matching',
        passwordSuccess: 'You have successfully reset your password. Please login again',
      },
    },
  },
  forgetPassword: {
    button: {
      loginInstead: 'Login Instead?',
      submit: 'Submit',
    },
    messages: {
      customerView: {
        enterEmail: 'Enter your email',
        requestFailure: 'Forget-password Failed. Please contact the system administrator',
        emailError: 'Please provide valid email',
        checkEmail: 'Please check your email',
        emailSentMsg: 'You should have received an email message with instructions on how to reset your password',
      },
    },
  },

  share: {
    fullStop: '.',
    SoldDiamondShareText:
      'This diamond has now been sold. Please contact your Brand Ambassador to discuss alternative diamonds.',
    buttons: {
      followUp: 'Follow-up',
      introduction: 'Introduction',
      masterClass: 'Masterclass query',
    },
    selectedSingular: '1 Diamond Selected',
    selectedPlural: (param1) => `${param1} Diamonds Selected`,
    editSelection: 'Edit selection',
    smsLimit: 'Messages sent via SMS are limited to 500 characters',
    emptyMsgError: 'Please enter a valid message',
    emptySubjectError: 'Please enter a valid subject',
    email: 'Email',
    emailError: 'Please enter a valid email address',
    to: 'To',
    toSms: 'To',
    enterEmailAddress: 'Enter email address(es)',
    subject: 'Subject',
    enterSubject: 'Enter subject',
    sms: 'SMS',
    smsInfo: 'Please enter the correct dialling code. For example: United Kingdom "+44"',
    smsError: 'Please enter a valid number',
    smsError86: 'We cannot share to +86 numbers',
    smsError1: 'We cannot share to +1 numbers',
    smsErrorDialCode: 'Please enter a valid dialling code',
    enterMobile: 'Enter mobile number(s)',
    addMessage: 'Add a message',
    addMessageInfo: 'Please add your message here',
    shareButton: 'Share selected Diamonds',
    subjects: {
      subject1: 'Your 1888 Master Diamonds information',
      subject2: 'Introducing the 1888 Master Diamonds',
      subject3: 'Thank you for joining our Virtual Diamond Masterclass',
    },
    messages: {
      message1: `Dear [client’s name],

Thank you for your interest in the De Beers 1888 Master Diamonds collection.

As discussed, please find enclosed some further information on your selected diamond [ / diamonds] for your reference.

I remain at your disposal for any questions.

Best regards,

[BA’s name]`,
      message2: `Dear [client’s name],

I am delighted to announce the launch of the 1888 Master Diamonds collection, our latest curated selection of 19 exceptional natural diamonds.

I thought this particular piece could be of interest to you. Please find enclose its detailed description and further information on the collection.

I remain at your disposal for any questions.

Best regards,

[BA’s name]`,
      message3: `Dear [client’s name],

I am delighted you could attend our latest Virtual Diamond Masterclass presenting the 1888 Master Diamonds Collection.

I noticed you had some questions regarding this piece in particular. I thought to share with you some further details on it and more information on the collection.

I remain at your disposal for any questions.

Best regards,

[BA’s name]`,
    },
    terms: 'For further information about how we process your personal data, please see our',
    termsLink: 'privacy policy',
    termsUrl: 'https://www.debeers.com/en-us/privacy-policy.html',
  },
  list: {
    title: 'The 1888 Master Diamonds by De Beers',
    removeAll: 'Remove all diamonds',
    remove: 'Remove Diamond',
    add: 'Add Diamond',
    continue: 'Continue with selected Diamonds',
    shareLimit: (param1, param2) =>
      `You can share up to <span>${param1}</span> Diamonds at once. Remove <span>${param2}</span> Diamond${
        param2 > 1 ? 's' : ''
      } to continue`,
  },
  success: {
    shared: 'Diamonds shared successfully',
    button: 'Back to Diamond Gallery',
    assuredBy: 'Assured by',
  },
  shareUnavailable: {
    headerTitleLong: 'The 1888 Master Diamonds by De Beers',
    headerTitleShort: 'De Beers 1888',
    title: 'Your selected diamonds are no longer available',
    subtitle: 'Please contact your Brand Ambassador for more information',
    deBeersLink: 'https://www.debeers.co.uk/en-gb',
    masterLink: 'https://www.debeers.co.uk/en-gb/the-1888-master-diamonds.html',
  },
}

export default translation
