import createActionSet from 'utils/createActionSet'

const LOGIN = createActionSet('LOGIN')
const LOGOUT = createActionSet('LOGOUT')
const LOAD_USER = createActionSet('LOAD_USER')

export const USER_ACTION_TYPES = {
  LOGIN,
  LOGOUT,
  LOAD_USER,
}
