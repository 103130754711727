import { LANGUAGE_ACTION_TYPES as ACTION_TYPES } from '../actionTypes'

export function setLanguageAction(payload) {
  return (dispatch) => {
    dispatch({ type: ACTION_TYPES.SET_LANGUAGE, payload })
  }
}

export function clearLanguageAction() {
  return (dispatch) => {
    dispatch({ type: ACTION_TYPES.CLEAR_LANGUAGE })
  }
}
