import React, { useState } from 'react'
import useLanguage from 'locale'
import { useHistory } from 'react-router'
import { Box } from '@material-ui/core'
import { isTablet, isMobile } from 'react-device-detect'
import classnames from 'classnames'

import { ReactComponent as DBJLogoIcon } from 'assets/icons/dbj_logo.svg'
import Header from 'containers/Header'
import testIds from 'config/testIds'

import Title from '../Title'
import DiamondTitle from '../DiamondTitle'
import DiamondProvenance from '../DiamondProvenance'
import DiamondImage from '../DiamondImage'
import DiamondVideo from '../DiamondVideo'
import DiamondScan from '../DiamondScan'
import DiamondProps from '../DiamondProps'
import styles from './styles'

import { useSelector } from 'react-redux'

import { selectFooter, selectIntroduction } from 'store/selectors'

const DiamondPage = ({
  diamond,
  isSelected,
  onSelect,
  recipientView = false,
  lang,
  translation,
  diamonds = [],
  diamondIntroduction,
  diamondFooter,
}) => {
  const history = useHistory()
  const classes = styles()
  const [mobile] = useState(isMobile && !isTablet)
  const copy = translation.diamondExperience
  const ids = testIds.diamond
  const translations = useLanguage()

  let soldDiamondInRecipientView = false
  if (recipientView && diamond) {
    if (diamond.isSold) {
      soldDiamondInRecipientView = true
    } else {
      soldDiamondInRecipientView = false
    }
  }

  let footer = useSelector(selectFooter)
  let introduction = useSelector(selectIntroduction)
  if (recipientView) {
    footer = diamondFooter
    introduction = diamondIntroduction
  }

  const populateDiamondImages = (diamond) => {
    const imageType = ['heart', 'arrow', 'idealScope', 'asetScope', 'fluorescence']
    const images = diamond.images
    if (!images) return null
    return imageType.map((eachImageType) => {
      const image = images[eachImageType]
      if (typeof image === 'undefined' || image === null || (image && Object.keys(image) === 0)) return null
      return (
        <Box
          className={classnames(classes.section, classes.centered, classes.wide, classes.diamondImage)}
          key={eachImageType}
          data-test-id={testIds.diamond[eachImageType]}
        >
          <DiamondImage diamondId={diamond.tracr_id} imageProps={image} />
        </Box>
      )
    })
  }

  const getImageByType = (type) => {
    let url = ''
    let altText = ''
    switch (type) {
      case 'headerBg':
        url = diamond?.header?.backgroundImage?.url
        altText = diamond?.header?.backgroundImage?.alternativeText
        break
      case 'faceUp':
        url = diamond?.header?.diamondImage?.url
        altText = diamond?.header?.diamondImage?.alternativeText
        break
      case 'provenance':
        url = diamond?.provenance?.provenance?.backgroundImage?.url
        altText = diamond?.provenance?.provenance?.backgroundImage?.alternativeText
        break
      case 'diagram':
        url = diamond?.dimensions?.measurement?.url
        altText = diamond?.dimensions?.measurement?.alternativeText
        break
      case 'inclusionMap':
        url = diamond?.dimensions?.inclusions?.url
        altText = diamond?.dimensions?.inclusions?.alternativeText
        break
      case 'footer':
        url = footer?.backgroundImage?.url
        altText = footer?.backgroundImage?.alternativeText
        break
      default:
        return { url, altText }
    }
    return { url, altText }
  }

  const getImageTextByType = (type, diamond) => {
    if (type === 'faceUp') return diamond?.topText
    if (type === 'provenance') return diamond?.provenance?.description

    return ''
  }

  const getVideoProps = () => {
    const diamondDetails = {
      cut: diamond.cut,
      clarity: diamond.clarity,
      caratWeight: diamond.caratWeight,
      colour: diamond.colour,
      provenance: diamond.provenance,
      dbMarque: diamond.dbMarque,
      video: diamond.video,
    }
    return diamondDetails
  }
  const actionText = () => {
    if (isSelected) {
      return <>{copy.cancelSelection}</>
    } else {
      return <>{copy.selectDiamond}</>
    }
  }

  const toggleSelectDiamond = () => {
    if (onSelect) onSelect()
  }
  const gotoListView = () => {
    history.push('/')
  }

  if (!diamond) return null

  return (
    <Box className={classes.diamondContainer} data-test-id={ids.container}>
      <Header
        withHistoryAction={!recipientView && !mobile}
        onBack={gotoListView}
        actionText={actionText}
        actionActive={isSelected}
        action={diamond.isSold ? null : !recipientView && toggleSelectDiamond}
        isDiamondPage={true}
        isRecipientView={recipientView}
      >
        {recipientView ? (
          <Box component="a" href="https://www.debeers.co.uk/en-gb/home" target="_blank" className={classes.headerLink}>
            {copy.headingTitle}
          </Box>
        ) : (
          <Title diamond={diamond} />
        )}
      </Header>
      <Box className={classes.content}>
        <Box className={classes.header} data-test-id={ids.header}>
          <Box className={classes.headerBackground}>
            <DiamondImage diamondId={diamond.tracrId} imageProps={getImageByType('headerBg')} withLoader={false} />
          </Box>
          <Box className={classes.headerLogo}>
            <DBJLogoIcon />
          </Box>
          <Box className={classes.headerWrapper}>
            <Box className={classes.headerTitle}>
              <DiamondTitle diamond={diamond} mobile={mobile} lang={lang} testId={testIds.diamond.headerTitle} />
            </Box>
            <Box className={classes.headerImageContainer}>
              <Box className={classes.headerImageBorder}>
                <DiamondImage diamondId={diamond.tracrId} imageProps={getImageByType('faceUp')} />
              </Box>
            </Box>
          </Box>
        </Box>
        {soldDiamondInRecipientView && (
          <Box className={classes.soldSharedDiamond}>{translations.share.SoldDiamondShareText}</Box>
        )}
        <Box
          className={classnames(classes.section, classes.centered, classes.topTextContainer)}
          data-test-id={ids.topText}
        >
          <Box
            className={classes.topText}
            dangerouslySetInnerHTML={{ __html: getImageTextByType('faceUp', diamond) }}
          />
        </Box>
        {!soldDiamondInRecipientView && (
          <>
            <Box className={classnames(classes.section, classes.intro)} data-test-id={ids.intro}>
              <Box className={classes.introContent}>
                <Box component="img" src={introduction?.backgroundImage?.url} />

                <Box className={classes.introOverlayContainer}>
                  <Box
                    className={classes.introLabel}
                    dangerouslySetInnerHTML={{ __html: introduction?.title?.toUpperCase() }}
                  />
                  <Box
                    component="h3"
                    className={classes.introText}
                    dangerouslySetInnerHTML={{ __html: introduction?.description }}
                  />
                </Box>
              </Box>
            </Box>
            <Box className={classnames(classes.section, classes.provenance)} data-test-id={ids.provenance}>
              <DiamondProvenance
                diamond={diamond}
                image={getImageByType('provenance')}
                translation={translation}
                lang={lang}
              />
            </Box>

            <Box
              className={classnames(classes.section, classes.centered, classes.provenanceDescription)}
              data-test-id={ids.provenanceDescription}
            >
              <Box
                className={classes.provenanceDescriptionText}
                dangerouslySetInnerHTML={{ __html: getImageTextByType('provenance', diamond) }}
              />
            </Box>

            {diamond && diamond.video && (
              <Box className={classnames(classes.section, classes.wide, classes.video)} data-test-id={ids.video}>
                <DiamondVideo diamondId={diamond.tracrId} videoProps={getVideoProps()} />
              </Box>
            )}

            {populateDiamondImages(diamond)}
            <Box
              className={classnames(classes.section, classes.centered, classes.diamondInfo)}
              data-test-id={ids.diamondInfo}
            >
              <Box component="h3" className={classes.diamondInfoLabel}>
                {copy.diamondInformation}
              </Box>
              <Box className={classes.diamondInfoTitle}>
                <DiamondTitle
                  diamond={diamond}
                  size="s"
                  mobile={mobile}
                  lang={lang}
                  testId={testIds.diamond.infoTitle}
                />
              </Box>
              {diamond && diamond.scan && (
                <Box className={classes.diamondInfoScanContainer} data-test-id={ids.diamondScan}>
                  <DiamondScan scanProps={diamond.scan} />
                </Box>
              )}
              <Box className={classes.diamondInfoDimensions} data-test-id={ids.diamondDimensions}>
                <Box
                  className={classnames(classes.dimensionBlock, classes.leftBlock)}
                  data-test-id={testIds.diamond.imageLeft}
                >
                  <DiamondImage diamondId={diamond.tracrId} imageProps={getImageByType('diagram')} />
                </Box>
                <Box
                  className={classnames(classes.dimensionBlock, classes.rightBlock)}
                  data-test-id={testIds.diamond.imageRight}
                >
                  <DiamondImage diamondId={diamond.tracrId} imageProps={getImageByType('inclusionMap')} />
                </Box>
              </Box>
              <Box className={classes.diamondInfoProps} data-test-id={ids.diamondProps}>
                <DiamondProps diamond={diamond} lang={lang} />
              </Box>
            </Box>
            <Box
              className={classnames(classes.footer, { [classes.footerSelected]: isSelected })}
              data-test-id={ids.footer}
            >
              <Box className={classes.footerContent}>
                <Box className={classes.footerImage}>
                  <DiamondImage diamondId={diamond.tracrId} imageProps={getImageByType('footer')} withLoader={false} />
                </Box>

                <Box className={classes.footerOverlayContainer}>
                  <Box className={classes.footerOverlayLabel}>{footer?.title?.toUpperCase()}</Box>
                  <Box
                    className={classes.footerOverlayText}
                    dangerouslySetInnerHTML={{ __html: footer?.description }}
                  />
                  <Box
                    component="a"
                    href={footer?.link?.url}
                    target="_blank"
                    className={classes.footerOverlayLink}
                    data-test-id={ids.findOutMore}
                  >
                    {footer?.link?.text}
                  </Box>
                </Box>
              </Box>
            </Box>
          </>
        )}
      </Box>
    </Box>
  )
}

export default DiamondPage
