import React, { useState, useEffect, memo } from 'react'
import PropTypes from 'prop-types'
import { TextField } from '@material-ui/core'
import { uniqBy } from 'lodash'
import { uuid } from 'uuidv4'
import Tag from 'components/Tag'
import { prepareAndSplit } from './utils'
import makeStyles from './styles'

const TagManager = ({
  placeholder,
  allowDuplicates,
  allowEditing,
  delimiters,
  beforeDelimiters,
  afterDelimiters,
  validation,
  onUpdate,
  onFocus,
  onBlur,
  type = 'text',
}) => {
  const [tags, setTags] = useState([])
  const classes = makeStyles()

  const submitTag = (eventValue) => {
    const value = eventValue.trim()

    if (value !== '') {
      const result = prepareAndSplit(value, delimiters, beforeDelimiters, afterDelimiters)
      const newValues = result.map((value) => ({
        id: uuid(),
        value,
        valid: validation ? validation(value) : true,
        editable: allowEditing,
      }))

      const allValues = [...tags, ...newValues]
      const valuesDeduplicated = allowDuplicates ? allValues : uniqBy(allValues, (x) => x.value)

      setTags(valuesDeduplicated)
    }
  }

  const handleKeyDown = (event) => {
    if (['Tab', 'Enter'].includes(event.key)) {
      if (event.target.value.trim() !== '') {
        event.preventDefault()

        submitTag(event.target.value)
        event.target.value = ''
      }
    }

    if (['Backspace'].includes(event.key)) {
      if (event.target.value.trim() === '' && tags.length > 0) {
        handleTagRemoved(tags[tags.length - 1].id)
      }
    }
  }

  const handleBlur = (event) => {
    submitTag(event.target.value)
    event.target.value = ''
    if (onBlur) {
      onBlur()
    }
  }

  const handleTagRemoved = (id) => setTags(tags.filter((tag) => tag.id !== id))

  const handleTagUpdated = (id, value) => {
    setTags(
      tags.map((tag) => {
        if (tag.id === id) {
          return {
            ...tag,
            value,
            valid: validation ? validation(value) : true,
          }
        }

        return tag
      })
    )
  }

  useEffect(() => {
    if (onUpdate) {
      onUpdate(tags)
    }
  }, [onUpdate, tags])

  return (
    <div className={classes.container}>
      {tags.map((tag) => (
        <Tag key={tag.id} {...tag} onDelete={handleTagRemoved} onUpdate={handleTagUpdated} />
      ))}
      <TextField
        type={type}
        onKeyDown={handleKeyDown}
        onBlur={handleBlur}
        onFocus={onFocus}
        placeholder={tags.length > 0 ? '' : placeholder}
        className={classes.input}
        InputProps={{
          disableUnderline: true,
          classes: {
            input: classes.input,
          },
        }}
        classes={{
          root: classes.root,
        }}
      />
    </div>
  )
}

TagManager.defaultProps = {
  placeholder: '',
  allowDuplicates: true,
  allowEditing: true,
  delimiters: [],
  beforeDelimiters: [],
  afterDelimiters: [],
}

TagManager.propTypes = {
  placeholder: PropTypes.string,
  allowDuplicates: PropTypes.bool,
  allowEditing: PropTypes.bool,
  beforeDelimiters: PropTypes.array,
  afterDelimiters: PropTypes.array,
  delimiters: PropTypes.array,
  validation: PropTypes.func,
  onUpdate: PropTypes.func,
}

export default memo(TagManager)
