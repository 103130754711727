import React from 'react'
import PropTypes from 'prop-types'
import Card from '../Card'
import styles from './styles'

const CardList = ({ cards, imageSize, onCardClick }) => {
  const soldDiamondList = cards.filter((eachDiamond) => eachDiamond.isSold)
  const unsoldDiamondList = cards.filter((eachDiamond) => !eachDiamond.isSold)
  const classes = styles()
  return (
    <>
      <div className={classes.container}>
        {unsoldDiamondList.map((item) => (
          <Card key={item.id} {...item} imageSize={imageSize} onClick={onCardClick} />
        ))}
      </div>
      {soldDiamondList.length > 0 && (
        <>
          <div className={classes.soldHeader}>Sold Diamonds</div>
          <div className={classes.container}>
            {soldDiamondList.map((item) => (
              <Card key={item.id} {...item} imageSize={imageSize} onClick={onCardClick} />
            ))}
          </div>
        </>
      )}
    </>
  )
}

CardList.propTypes = {
  cards: PropTypes.array.isRequired,
  imageSize: PropTypes.shape({
    width: PropTypes.number.isRequired,
    height: PropTypes.number.isRequired,
  }).isRequired,
  onCardClick: PropTypes.func,
}

export default CardList
