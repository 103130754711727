import makeStyles from '@material-ui/core/styles/makeStyles'

export default makeStyles((theme) => ({
  container: {
    display: 'inline-block',
    marginRight: 8,
    marginTop: 8,
    userSelect: 'none',
  },
  label: {
    fontFamily: 'Montserrat',
    fontWeight: 500,
    paddingLeft: 16,
    fontSize: 16,
  },
  invalid: {
    color: '#fff',
    borderRadius: 0,
    border: 0,
    background: '#C20746',
    paddingRight: 4,
    '&:focus': {
      background: '#C20746',
    },
  },
  valid: {
    border: 0,
    borderRadius: 0,
    background: '#DDDEE2',
    paddingRight: 4,
    color: '#0D1B40',
    '&:focus': {
      background: '#DDDEE2',
    },
  },
  deleteIcon: {
    color: '#ccc',
    width: 12,
  },
  invalidDeleteIcon: {
    width: 12,
    '& path': {
      stroke: '#fff',
    },
  },
  editInput: {
    fontWeight: 600,
    lineHeight: '16px',
    paddingLeft: 8,
    color: '#fff',
    border: '1px solid rgba(255, 255, 255, 0.12)',
    outline: 'none',
    background: 'none',
  },
}))
