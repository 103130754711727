/**
 * Process top level string, splitting by delimiters
 *
 * @param {string} value - The original string value.
 * @param {array} delimiters - All delimiters you want to split by.
 * @param {array} beforeDelimiters - All string replacements you want to apply before splitting.
 * @param {array} afterDelimiters - All string replacements you want to apply after splitting.
 */
export const prepareAndSplit = (value, delimiters, beforeDelimiters, afterDelimiters) => {
  const defaultDelimiter = { value: ' ', replace: true }
  const normalisedValues = splitAndNormalise(clean(value, beforeDelimiters), defaultDelimiter)

  const valuesWithoutDelimiter = normalisedValues.filter((value) => {
    const valueContainsDelimiter = delimiters.some((delimiter) => containsDelimiter(value, delimiter))
    return valueContainsDelimiter === false
  })

  const valuesWithDelimiter = normalisedValues.filter((value) => valuesWithoutDelimiter.includes(value) === false)
  const valuesWithDelimiterSplit = splitValuesByAllDelimiters(valuesWithDelimiter, delimiters)

  return [...valuesWithDelimiterSplit, ...valuesWithoutDelimiter].map((x) => clean(x, afterDelimiters))
}

/**
 * Perform cleaning functions before splitting
 *
 * @param {string} value - The string value.
 * @param {array} cleaners - All cleaners you want to apply.
 */
export const clean = (value, cleaners) =>
  cleaners.reduce((accumulator, currentValue, currentIndex, array) => {
    const test = currentValue.search
    const replacement = currentValue.replace

    return accumulator.replace(new RegExp(test, 'g'), replacement)
  }, value)

/**
 * A recursive function that splits strings by multiple delimiters.
 *
 * @param {array} values - An array of string values.
 * @param {array} delimiters - All delimiters you want to split by.
 */
export const splitValuesByAllDelimiters = (values, delimiters) => {
  const delimiterIndex = delimiters
    .filter((x) => x.replace !== false)
    .findIndex((delimiter) => containsDelimiter(values.join(''), delimiter))

  if (delimiterIndex >= 0) {
    const delimiter = delimiters[delimiterIndex]
    const valuesWithoutDelimiter = values.filter((x) => containsDelimiter(x, delimiter) === false)

    return splitValuesByAllDelimiters(
      [...valuesWithoutDelimiter, ...splitValuesByDelimiter(values, delimiter)],
      delimiters
    )
  }

  return values
}

/**
 * A function that splits multiple values by a single delimiter.
 *
 * @param {array} values - An array of string values.
 * @param {object} delimiter - The delimiter you want to split by.
 */
export const splitValuesByDelimiter = (values, delimiter) => {
  const byDelimiter = values.filter((x) => containsDelimiter(x, delimiter))
  const normalisedValues = splitAndNormalise(byDelimiter.join(''), delimiter)

  const test = delimiter.value
  const replacement = typeof delimiter.replace === 'string' ? delimiter.replace : ''

  return normalisedValues.map((x) => x.replace(new RegExp(`\\${test}`, 'g'), replacement))
}

/**
 * Splits a string by delimiter and removes any trailing spaces.
 *
 * @param {string} value - The string you want to split/normalise.
 * @param {object} delimiter - The delimiter you want to split by.
 */
export const splitAndNormalise = (value, delimiter) =>
  value
    .split(delimiter.value)
    .map((x) => x.trim())
    .filter((x) => x !== '')

/**
 * Checks if a string contains a delimiter
 *
 * @param {string} value - The string you want to search.
 * @param {object} delimiter - The delimiter you want to check for.
 */
export const containsDelimiter = (value, delimiter) => value.includes(delimiter.value)
