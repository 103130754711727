import env from '@beam-australia/react-env'
import TextField from '@material-ui/core/TextField'
import Typography from '@material-ui/core/Typography'
import axios from 'axios'
import React, { useState } from 'react'
import { useHistory, useLocation } from 'react-router-dom'
import ResetPasswordDialog from 'components/Dialog/ResetPasswordDialog'
import styles from './styles'
import useLanguage from 'locale'

function useQuery() {
  return new URLSearchParams(useLocation().search)
}

const ResetPassword = () => {
  const classes = styles()
  const history = useHistory()
  const translations = useLanguage()
  const [resetPasswordErrorMsg, setResetPasswordErrorMsg] = useState('')
  const [resetSuccessMsg, setResetSuccessMsg] = useState('')
  const [dialogOpen, setDialogOpen] = useState(false)

  // "code" from browser query parameter
  const code = useQuery().get('code')

  const [password, setPassword] = useState('')
  const [passwordConfirmation, setPasswordConfirmation] = useState('')

  const resetPassCheck = {
    passwordMismatch: translations.resetPassword.messages.customerView.passwordMismatch,
    followInstruction: translations.resetPassword.messages.customerView.followInstruction,
    passwordSuccess: translations.resetPassword.messages.customerView.passwordSuccess,
    requestFailure: translations.resetPassword.messages.customerView.requestFailure,
  }

  const handleDialogClose = () => {
    setDialogOpen(false)
    history.push('/login')
  }

  const onPasswordResetClick = (e) => {
    e.preventDefault()

    try {
      if (password !== passwordConfirmation) {
        throw new Error(resetPassCheck.passwordMismatch)
      }

      const endpoint = env('API_URL') + '/auth/reset-password'

      const postData = { code, password, passwordConfirmation }
      axios
        .post(endpoint, postData)
        .then((response) => {
          setResetSuccessMsg(resetPassCheck.passwordSuccess)
          setDialogOpen(true)
        })
        .catch((resetError) => {
          if (resetError.response.status === 400) {
            setResetPasswordErrorMsg(resetPassCheck.followInstruction)
          } else {
            setResetPasswordErrorMsg(resetPassCheck.requestFailure)
          }
        })
    } catch (err) {
      setResetPasswordErrorMsg(err.message)
    }
  }

  return (
    <>
      {dialogOpen && (
        <ResetPasswordDialog
          open={dialogOpen}
          handleDialogClose={handleDialogClose}
          resetSuccessMsg={resetSuccessMsg}
        />
      )}
      <form className={classes.loginFacility} noValidate onSubmit={onPasswordResetClick}>
        <div style={{ marginTop: '10px' }}>
          <TextField
            placeholder={translations.resetPassword.messages.customerView.enterNewPassword}
            type="password"
            value={password}
            onChange={(e) => setPassword(e.target.value)}
            InputProps={{ classes: { input: classes.input } }}
          />
        </div>
        <div style={{ marginTop: '10px' }}>
          <TextField
            placeholder={translations.resetPassword.messages.customerView.confirmNewPassword}
            type="password"
            value={passwordConfirmation}
            onChange={(e) => setPasswordConfirmation(e.target.value)}
            InputProps={{ classes: { input: classes.input } }}
          />
        </div>
        {resetPasswordErrorMsg && (
          <div>
            <Typography variant="subtitle2" className={classes.errorStatus}>
              {resetPasswordErrorMsg}
            </Typography>
          </div>
        )}
        <div style={{ marginTop: 34 }}>
          <button
            className={classes.button}
            variant="contained"
            color="primary"
            type={'submit'}
            onClick={onPasswordResetClick}
          >
            {translations.resetPassword.button.resetPasswordBtnText}
          </button>
        </div>
      </form>
    </>
  )
}

export default ResetPassword
