import makeStyles from '@material-ui/core/styles/makeStyles'

export default makeStyles((theme) => ({
  footer: {
    fontFamily: 'Montserrat',
    fontWeight: 600,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    position: 'fixed',
    bottom: 0,
    width: '100%',
    background: '#fff',
    boxShadow: '0px -4px 20px rgba(0, 0, 0, 0.1)',
  },
  buttonContainer: {
    padding: '8px 0',
    flexGrow: 1,
    alignItems: 'center',
    cursor: 'pointer',
    '&:active': {
      color: '#fff',
      background: '#0D1B40',
      '& svg path': {
        stroke: '#fff',
      },
    },
    '@media (hover)': {
      '&:hover': {
        color: '#fff',
        background: '#0D1B40',
        '& svg path': {
          stroke: '#fff',
        },
      },
    },
  },
  count: {
    margin: '0 28px',
  },
}))
