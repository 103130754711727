import makeStyles from '@material-ui/core/styles/makeStyles'

export default makeStyles(() => ({
  container: {
    display: 'grid',
    maxWidth: '1320px',
    padding: '0 32px 96px 32px',
    gridTemplateColumns: '1fr',
    '@media (min-width: 600px)': {
      gridTemplateColumns: '1fr 1fr',
      padding: '0 65px 65px 65px',
    },
    '@media (min-width: 1024px)': {
      gridTemplateColumns: '1fr 1fr 1fr',
    },
    '@media (min-width: 1337px)': {
      gridTemplateColumns: '1fr 1fr 1fr 1fr',
    },
    gridGap: '24px',
    margin: '0 auto',
  },

  soldHeader: {
    display: 'grid',
    textAlign: 'center',
    justifyContent: 'center',
    fontSize: '30px',
    fontWeight: 'bolder',
    color: '#0D1B40',
    borderBottom: '3px solid #D3D6DA',
    maxWidth: '1320px',
    padding: '0 32px 32px',
    marginBottom: '60px',
    gridGap: '24px',
    margin: '0 auto',
  },
}))
