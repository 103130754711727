import React from 'react'
import { Link } from 'react-router-dom'
import styles from './styles'

const Welcome = () => {
  const classes = styles()

  return (
    <div className={classes.item}>
      <div className={classes.linkstyle}>
        <Link to="/login">Login</Link>
      </div>
      <div className={classes.linkstyle}>
        <Link to="/forgot-password">Forgot Password?</Link>
      </div>
    </div>
  )
}

export default Welcome
