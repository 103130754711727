import env from '@beam-australia/react-env'

const parseEnvVar = (variable) => {
  const value = env(variable) || ''

  if (!value) return []

  return value.replace(/\s/g, '').split(',')
}

const FEATURES_INCLUDED = parseEnvVar('FEATURES_INCLUDED')
const FEATURES_EXCLUDED = parseEnvVar('FEATURES_EXCLUDED')
const FEATURES_DISABLED = parseEnvVar('FEATURES_DISABLED')

// console.log('FEATURES_INCLUDED', FEATURES_INCLUDED)
// console.log('FEATURES_EXCLUDED', FEATURES_EXCLUDED)
// console.log('FEATURES_DISABLED', FEATURES_DISABLED)

const features = [
  {
    name: 'list',
    belongsTo: [],
  },
]

/**
 * Check if feature or one if its parents is in the FEATURES_EXCLUDED list
 */
const isFeatureExcluded = (feature) => {
  if (FEATURES_EXCLUDED.includes(feature.name)) {
    return true
  } else if (feature.belongsTo) {
    return feature.belongsTo.find((parentFeatureName) => {
      const parentFeature = features.find((feature) => feature.name === parentFeatureName)

      // ignore features that are not in the list
      if (!parentFeature) return false

      // check this feature
      return isFeatureExcluded(parentFeature)
    })
  }
}

/**
 * Check if feature is included in the UI using FEATURES_INCLUDED
 */
const isFeatureIncluded = (feature) => {
  if (FEATURES_INCLUDED.includes(feature.name)) {
    return true
  } else if (feature.belongsTo.length === 0) return true
}

/**
 * Check if the feature or one of its parents is disabled using
 * FEATURES_DISABLED
 */
const isFeatureDisabled = (feature) => {
  if (FEATURES_DISABLED.includes(feature.name)) {
    return true
  } else if (feature.belongsTo) {
    return feature.belongsTo.find((parentFeatureName) => {
      const parentFeature = features.find((feature) => feature.name === parentFeatureName)

      // ignore features that are not in the list
      if (!parentFeature) return false

      // check parent feature
      return isFeatureDisabled(parentFeature)
    })
  }
}

const getFeatureState = (feature) => {
  let state
  /**
   * 0 for excluded
   * 1 for disabled
   * 2 for included
   */

  if (isFeatureExcluded(feature)) {
    // feature is explicitly excluded from the UI
    return 0
  }

  // if feature is not excluded, check if its included using the FEATURES_INCLUDED
  if (isFeatureIncluded(feature)) {
    if (isFeatureDisabled(feature)) {
      /**
       * Even if feature is included, it can be still disabled, meaning it will show in the UI
       * but will be disabled/greyed out i.e. unavailable for interactions
       */
      state = 1
    } else {
      state = 2
    }
  } else {
    state = 0
  }

  return state
}

const featureToggles = {}

features.forEach((feature) => {
  /**
   * Check the strongest rule -> feature is excluded
   */

  const state = getFeatureState(feature)

  featureToggles[feature.name] = state
})

console.log('Features configured', featureToggles)

export default featureToggles
