import React from 'react'
import Box from '@material-ui/core/Box'
import Logo from 'assets/logo.png'
import LogoLight from 'assets/logo_light.png'

import styles from './styles'
import useLanguage from 'locale'

const Banner = ({ imageWidth, padding, variant = 'dark', isLink = false }) => {
  const classes = styles({ imageWidth, padding })
  const translations = useLanguage()

  const Image = () =>
    variant === 'light' ? (
      <img alt={'De Beers Jewellers'} src={LogoLight} className={classes.image} />
    ) : (
      <img alt={'De Beers Jewellers'} src={Logo} className={classes.image} />
    )

  return (
    <Box className={classes.container}>
      {isLink ? (
        <a href={translations.shareUnavailable.deBeersLink} target="_blank" rel="noopener noreferrer">
          <Image />
        </a>
      ) : (
        <Image />
      )}
    </Box>
  )
}

Banner.defaultProps = {
  imageWidth: 630,
}

export default Banner
