import React from 'react'
import { Box } from '@material-ui/core'

import styles from './styles'

const DiamondImage = ({ imageProps }) => {
  const classes = styles()

  return (
    <Box className={classes.diamondImageContainer}>
      <Box component="img" src={`${imageProps.url}`} alt={`${imageProps.altText}`} />
    </Box>
  )
}

export default DiamondImage
