import { clone } from 'ramda'
import messages from 'text/messages'
import { DIAMONDS_ACTION_TYPES } from '../actionTypes'

const initialState = {
  isLoading: false,
  error: null,
  data: [],
  sharedDiamonds: { diamonds: [], footer: '', introduction: '' },
}

const diamondsReducer = (state = initialState, { payload, type }) => {
  switch (type) {
    case DIAMONDS_ACTION_TYPES.GET_DIAMONDS.REQUEST: {
      const newState = clone(state)
      return { ...newState, isLoading: true, error: null }
    }

    case DIAMONDS_ACTION_TYPES.GET_DIAMONDS.FAILURE: {
      const newState = clone(state)
      return {
        ...newState,
        isLoading: false,
        error: payload.error || messages.login.failure,
      }
    }

    case DIAMONDS_ACTION_TYPES.GET_DIAMONDS.COMPLETE: {
      const newState = clone(state)

      return {
        ...newState,
        error: null,
        isLoading: false,
        data: payload,
      }
    }

    case DIAMONDS_ACTION_TYPES.GET_DIAMOND.REQUEST: {
      const newState = clone(state)
      return { ...newState, isLoading: true, error: null }
    }

    case DIAMONDS_ACTION_TYPES.GET_DIAMOND.FAILURE: {
      const newState = clone(state)
      return {
        ...newState,
        isLoading: false,
        error: payload.error || messages.login.failure,
      }
    }

    case DIAMONDS_ACTION_TYPES.GET_DIAMOND.COMPLETE: {
      const newState = clone(state)
      return {
        ...newState,
        error: null,
        isLoading: false,
        diamond: payload,
      }
    }

    case DIAMONDS_ACTION_TYPES.GET_SHARED_DIAMONDS.REQUEST: {
      const newState = clone(state)
      return { ...newState, isLoading: true, error: null }
    }

    case DIAMONDS_ACTION_TYPES.GET_SHARED_DIAMONDS.FAILURE: {
      const newState = clone(state)
      return {
        ...newState,
        isLoading: false,
        error: payload.error || messages.login.failure,
      }
    }

    case DIAMONDS_ACTION_TYPES.GET_SHARED_DIAMONDS.COMPLETE: {
      const newState = clone(state)
      return {
        ...newState,
        sharedDiamonds: {
          diamonds: payload.diamonds,
          footer: payload.footer,
          introduction: payload.introduction,
        },
      }
    }
    case DIAMONDS_ACTION_TYPES.CLEAR_DIAMONDS.CLEAR: {
      return initialState
    }
    default:
      return state
  }
}

export default diamondsReducer
