import env from '@beam-australia/react-env'
import featureToggles from 'config/featureToggles'
import translation from 'locale/en_GB/translation'
import DiamondPage from 'pages/diamond'
import List from 'pages/list'
import Share from 'pages/share'
import Success from 'pages/success'

const copy = translation.navigation

const allRoutes = [
  {
    title: copy.items.list,
    path: '/list',
    feature: 'list',
    component: List,
  },
  {
    title: copy.items.diamond,
    path: '/diamond/:id',
    feature: 'diamond',
    component: DiamondPage,
  },
  {
    title: copy.items.share,
    path: '/share',
    feature: 'share',
    component: Share,
  },
  {
    title: copy.items.success,
    path: '/success',
    feature: 'success',
    component: Success,
  },
]

const extractAndPrepareAvailableRoutes = (routes) => {
  const availableRoutes = routes.filter((route) => {
    // include those with no feature name
    if (!route.feature) return true

    // filter out excluded features
    if (featureToggles[route.feature] === 0) return false

    if (featureToggles[route.feature] === 1) {
      route.disabled = true
      // set tooltip here as well
    }

    if (route.children) route.children = extractAndPrepareAvailableRoutes(route.children)

    // if route has children, but they were filtered out, exclude this route
    if (route.children && route.children.length === 0) return false
    else return true
  })

  return availableRoutes
}

const routes = extractAndPrepareAvailableRoutes(allRoutes)

const DEFAULT_ROUTE = env('DEFAULT_ROUTE')
// TODO: getDefaultRoute logic
export const defaultRoutePath = DEFAULT_ROUTE || '/list'

export default routes
