import { Grid } from '@material-ui/core'
import { ReactComponent as LeftArrowSVG } from 'assets/icons/arrow_left.svg'
import { ReactComponent as RightArrowSVG } from 'assets/icons/arrow_right.svg'
import GTM_Event_TYPES from 'GTM/model'
import { deployGTMDataLayer } from 'GTM/service'
import useLanguage from 'locale'
import DiamondPage from 'pages/diamond/DiamondPage'
import ShareUnavailable from 'pages/shareUnavailable'
import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useHistory, useParams } from 'react-router'
import { getSharedDiamondsAction, setShareIdAction } from 'store/actions'
import { selectSelectedLanguage, selectSharedDiamonds } from 'store/selectors'
import { enhancedUrl } from 'utils/helper'
import makeStyles from './styles'

const Recipient = ({ match }) => {
  const classes = makeStyles()
  const history = useHistory()
  const params = useParams()
  const dispatch = useDispatch()
  const sharedDiamondsInfo = useSelector(selectSharedDiamonds)
  const sharedDiamonds = sharedDiamondsInfo.diamonds
  const diamondFooter = sharedDiamondsInfo.footer
  const diamondIntroduction = sharedDiamondsInfo.introduction
  const selectedLanguage = useSelector(selectSelectedLanguage)
  const urlLang = params.lang ? params.lang : `/${enhancedUrl(selectedLanguage)}`
  const translations = useLanguage()
  const [page, setPage] = useState(0)
  const diamond = sharedDiamonds[page]
  if (diamond && Object.keys(diamond).length > 0) {
    let dataLayer = {
      event: GTM_Event_TYPES.CLIENT_DIAMOND_SPECIFICS,
      dbMarque: diamond.dbMarque,
      caratWeight: diamond.caratWeight,
      colour: diamond.colour,
      clarity: diamond.clarity,
      cut: diamond.cut,
      availability: diamond.isSold ? 'sold' : 'available',
    }

    if (!diamond.isSold) {
      dataLayer = { ...dataLayer, provenance: diamond.provenance ? diamond.provenance.provenance.name : '' }
    }
    deployGTMDataLayer(dataLayer)
  }

  const initialPage = () => {
    const pos = parseInt(match.params.pos)

    if (pos <= sharedDiamonds.length && pos >= 1) {
      return pos - 1
    } else {
      history.push(`/${urlLang}/shared/${match.params.id}/1`)
      return 0
    }
  }

  useEffect(() => {
    if (sharedDiamonds.length === 0) {
      dispatch(setShareIdAction(match.params.id))
      dispatch(getSharedDiamondsAction(match.params.id, urlLang))
    }
  }, [dispatch, match.params.id]) // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    if (sharedDiamonds && sharedDiamonds.length) {
      setPage(initialPage())
    }
  }, [page, JSON.stringify(sharedDiamonds)]) // eslint-disable-line react-hooks/exhaustive-deps

  const nextPage = () => {
    const newPage = page + 1 > sharedDiamonds.length - 1 ? 0 : page + 1
    setPage(newPage)
    history.push(`/${urlLang}/shared/${match.params.id}/${newPage + 1}`)
  }

  const prevPage = () => {
    const newPage = page <= 0 ? sharedDiamonds.length - 1 : page - 1
    setPage(newPage)
    history.push(`/${urlLang}/shared/${match.params.id}/${newPage + 1}`)
  }

  return (
    <>
      <DiamondPage
        diamond={diamond}
        recipientView={true}
        translation={translations}
        diamonds={[]}
        diamondIntroduction={diamondIntroduction}
        diamondFooter={diamondFooter}
      />
      {sharedDiamonds.length === 0 && <ShareUnavailable />}
      {sharedDiamonds.length > 1 && (
        <Grid container className={classes.footer}>
          <Grid item className={classes.buttonContainer} onClick={prevPage}>
            <Grid container alignItems="center">
              <Grid item>
                <LeftArrowSVG />
              </Grid>
              <Grid item>{translations.diamondExperience.prev}</Grid>
            </Grid>
          </Grid>
          <Grid item className={classes.count}>
            {page + 1} / {sharedDiamonds.length} {translations.diamondExperience.diamonds}
          </Grid>
          <Grid item className={classes.buttonContainer} onClick={nextPage}>
            <Grid container alignItems="center" justify="flex-end">
              <Grid item>{translations.diamondExperience.next}</Grid>
              <Grid item>
                <RightArrowSVG />
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      )}
    </>
  )
}

export default Recipient
