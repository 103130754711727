import { useSelector } from 'react-redux'
import { selectSelectedLanguage } from 'store/selectors'
import en_GB from './en_GB/translation'
import zh_CN from './zh_CN/translation'
import zh_Hant from './zh_Hant/translation'

function useLanguage() {
  const selectedLanguage = useSelector(selectSelectedLanguage)

  switch (selectedLanguage) {
    case 'zh_Hant':
      return zh_Hant

    case 'zh_CN':
      return zh_CN

    default:
      return en_GB
  }
}

export default useLanguage
