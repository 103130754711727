import makeStyles from '@material-ui/core/styles/makeStyles'

export default makeStyles((theme) => ({
  diamondPropsContainer: {},
  content: {
    '@media (min-width: 1024px)': {
      display: 'flex',
      flexDirection: 'column',
    },
  },
  column: {
    '& > div': {
      paddingTop: '32px',
      '&:first-of-type': {
        paddingTop: '0',
      },
    },
    '@media (min-width: 1024px)': {
      display: 'flex',
      flexWrap: 'wrap',
      '& > div': {
        boxSizing: 'border-box',
        width: '50%',
        paddingTop: '0',
      },
      '& > div:nth-child(even)': {
        paddingLeft: '30px',
      },
      '& > div:nth-child(odd)': {
        paddingRight: '30px',
      },
      '& > div:nth-child(n+3)': {
        paddingTop: '60px',
      },
    },
  },
  left: {
    '@media (min-width: 1024px)': {},
  },
  right: {
    paddingTop: '80px',
    '@media (min-width: 1024px)': {
      paddingTop: '150px',
    },
  },
}))
