import makeStyles from '@material-ui/core/styles/makeStyles'

export default makeStyles((theme) => ({
  container: {
    height: '100vh',
    width: '100vw',
    background: 'url(/images/background.png) center center / cover no-repeat',
    overflowX: 'hidden',
  },
  title: {
    fontSize: '32px',
    fontWeight: 700,
    lineHeight: '50px',
    letterSpacing: '0px',
    textAlign: 'center',
    marginBottom: '16px',
    '@media (max-width: 640px)': {
      fontSize: '24px',
      lineHeight: '37px',
    },
  },
  subTitle: {
    fontFamily: 'Montserrat',
    fontSize: '18px',
    fontWeight: 400,
    lineHeight: '28px',
    textAlign: 'center',
    '@media (max-width: 640px)': {
      fontSize: '16px',
      lineHeight: '25px',
    },
  },
  addressContainer: {
    display: 'flex',
    justifyContent: 'center',
    marginTop: '60px',
    '@media (max-width: 640px)': {
      flexDirection: 'column',
      marginTop: '16px',
    },
  },
  titleContainer: {
    padding: '24px',
    marginTop: '60px',
    '@media (max-width: 1024px)': {
      marginTop: '180px',
    },
    '@media (max-width: 640px)': {
      marginTop: '0px',
    },
  },
}))
