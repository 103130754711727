import makeStyles from '@material-ui/core/styles/makeStyles'

export default makeStyles(() => ({
  container: {
    position: 'fixed',
    bottom: 0,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    height: '69px',
    userSelect: 'none',
    background: '#0D1B40',
    color: '#ffffff',
    fontFamily: 'Montserrat',
    fontSize: '18px',
    fontWeight: '600',
    cursor: 'pointer',
    width: '100%',
    borderRadius: 0,
    zIndex: 2,
  },
}))
