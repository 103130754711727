import React from 'react'
import testIds from 'config/testIds'

import { ReactComponent as LogoSVG } from 'assets/icons/logo.svg'
import styles from './styles'
import useLanguage from 'locale'

const Header = ({ titleLong, titleShort }) => {
  const classNames = styles()
  const translations = useLanguage()

  return (
    <div className={classNames.container} data-test-id={testIds.header.container}>
      <div className={classNames.left}>
        <a
          href="https://www.tracr.com/"
          target="_blank"
          rel="noopener noreferrer"
          data-test-id={testIds.header.tracrLink}
        >
          <LogoSVG />
        </a>
      </div>
      <div className={classNames.right}>
        <div className={classNames.text}>
          <a
            href={translations.shareUnavailable.masterLink}
            target="_blank"
            rel="noopener noreferrer"
            data-test-id={testIds.header.masterLink}
          >
            <div className={classNames.long} data-test-id={testIds.header.textLogo}>
              {titleLong}
            </div>
            <div className={classNames.short} data-test-id={testIds.header.textLogo}>
              {titleShort}
            </div>
          </a>
        </div>
      </div>
    </div>
  )
}

export default Header
