import makeStyles from '@material-ui/core/styles/makeStyles'

export default makeStyles(() => ({
  container: {
    display: 'grid',
    gridTemplateRows: '4fr 3fr 1fr',
    background: 'url(images/background.png) center center / cover no-repeat',
    height: '100vh',
  },
  item: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
  start: {
    alignItems: 'flex-start',
  },
  loginFacility: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    height: '35vh',
  },
  passwordFix: {
    display: 'flex',
    justifyContent: 'center',
    color: '#0D1B40',
    fontSize: '18px',
    fontWeight: 600,
    marginTop: '64px',
    width: '50vw',
  },
  forgetpass_div: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    width: '40vw',
    marginTop: '47px',
  },
  button: {
    fontFamily: 'Montserrat',
    display: 'block',
    width: '224px',
    height: '40px',
    background: '#0D1B40',
    color: '#ffffff',
    fontSize: '12px',
    fontWeight: 600,
    border: 0,
    margin: '0 auto',
    cursor: 'pointer',
  },
  input: {
    width: '30vw',
    textAlign: 'center',
  },
  linkstyle: {
    fontFamily: 'Montserrat',
    color: '#ffffff',
    fontSize: '16px',
    fontWeight: 600,
    cursor: 'pointer',
    marginTop: '16px',
    textDecoration: 'underline',
  },

  errorStatus: {
    color: '#b71c1c',
  },
  backbutton: {
    display: 'flex',
    width: '100px',
    marginTop: '20px',
    marginLeft: '20px',
  },
}))
