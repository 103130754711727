import { USER_ACTION_TYPES } from '../actionTypes'

const initialState = {
  isLoading: false,
  error: null,
  data: null,
}

const userReducer = (state = initialState, { payload, type }) => {
  switch (type) {
    case USER_ACTION_TYPES.LOGIN.REQUEST:
    case USER_ACTION_TYPES.LOAD_USER.REQUEST:
      return { ...state, isLoading: true, error: null }
    case USER_ACTION_TYPES.LOGOUT.REQUEST: {
      return {
        data: null,
        isLoading: true,
        error: null,
      }
    }
    case USER_ACTION_TYPES.LOGIN.FAILURE:
    case USER_ACTION_TYPES.LOAD_USER.FAILURE: {
      return {
        ...state,
        isLoading: false,
        error: '',
      }
    }
    case USER_ACTION_TYPES.LOGOUT.FAILURE: {
      return {
        ...state,
        isLoading: false,
      }
    }
    case USER_ACTION_TYPES.LOGIN.COMPLETE:
    case USER_ACTION_TYPES.LOAD_USER.COMPLETE:
      return { ...state, isLoading: false, data: payload }
    case USER_ACTION_TYPES.LOGOUT.COMPLETE:
      return { ...state, isLoading: false }
    default:
      return state
  }
}

export default userReducer
