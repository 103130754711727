import makeStyles from '@material-ui/core/styles/makeStyles'

export default makeStyles((theme) => ({
  line: {
    fontFamily: 'Montserrat',
    fontSize: '16px',
    fontWeight: 500,
    lineHeight: '25px',
    textAlign: 'center',
    color: '#071332',
    '@media (max-width: 640px)': {
      fontSize: '14px',
      lineHeight: '22px',
    },
  },
}))
