import { clone } from 'ramda'
import { SELECTED_ACTION_TYPES, DIAMONDS_ACTION_TYPES } from '../actionTypes'

const initialState = {
  diamonds: [],
}

const selectedReducer = (state = initialState, { payload, type }) => {
  switch (type) {
    case DIAMONDS_ACTION_TYPES.SHARE_DIAMOND_TO_EMAIL.COMPLETE: {
      const newState = clone(state)
      return {
        ...newState,
        diamonds: [],
      }
    }
    case SELECTED_ACTION_TYPES.SELECT_DIAMOND: {
      const newState = clone(state)
      const diamondsOrdered = payload.diamonds.map((x) => x.dbMarque)
      return {
        ...newState,
        diamonds: Array.from(new Set(newState.diamonds.concat([payload.dbMarque]))).sort(
          (a, b) => diamondsOrdered.indexOf(a) - diamondsOrdered.indexOf(b)
        ),
      }
    }
    case SELECTED_ACTION_TYPES.DESELECT_DIAMOND: {
      const newState = clone(state)
      return { ...newState, diamonds: newState.diamonds.filter((x) => x !== payload.dbMarque) }
    }
    case SELECTED_ACTION_TYPES.CLEAR_SELECTION: {
      return initialState
    }
    default:
      return state
  }
}

export default selectedReducer
