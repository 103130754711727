const to2Digets = (num) => {
  if (!Number.isFinite(num)) return ''

  let floating = num.toString().split('.')[1]

  if (!floating) {
    floating = '00'
  } else {
    if (floating.length < 2) {
      floating = floating.concat('0')
    } else if (floating.length > 2) {
      floating = floating.slice(0, 2)
    }
  }

  return `${Math.floor(num)}.${floating}`
}

export default to2Digets
