import makeStyles from '@material-ui/core/styles/makeStyles'

export default makeStyles(() => ({
  container: {
    position: 'relative',
    width: 250,
  },
  dropdown: {
    fontFamily: 'Montserrat',
    fontWeight: 600,
    fontSize: 16,
    color: '#0D1B40',
    height: 48,
    background: '#DDDEE2',
    border: 0,
    borderRadius: 0,
    cursor: 'pointer',
    textIndent: 55,
    WebkitAppearance: 'none',
    width: 250,
    outline: 'none',
    paddingRight: 42,
  },
  icon: {
    position: 'absolute',
    top: 12,
    left: 22,
    pointerEvents: 'none',
  },
  arrow: {
    position: 'absolute',
    top: 12,
    right: 22,
    pointerEvents: 'none',
  },
}))
