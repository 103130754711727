import makeStyles from '@material-ui/core/styles/makeStyles'

export default makeStyles((theme) => ({
  diamondImageContainer: {
    '& img': {
      width: '100%',
      verticalAlign: 'top',
    },
  },
  diamondImageText: {
    paddingTop: '12px',
    ...theme.typography.sizeM,
    textAlign: 'center',
    color: theme.colors.darkBlue,
  },
}))
