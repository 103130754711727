import makeStyles from '@material-ui/core/styles/makeStyles'

export default makeStyles(() => ({
  item: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    flexDirection: 'column',
  },
  start: {
    alignItems: 'flex-start',
  },
  button: {
    fontFamily: 'Montserrat',
    display: 'block',
    width: '224px',
    height: '40px',
    background: '#0D1B40',
    color: '#ffffff',
    fontSize: '12px',
    fontWeight: 600,
    border: 0,
    margin: '0 auto',
    cursor: 'pointer',
  },
  linkstyle: {
    fontFamily: 'Montserrat',
    color: '#ffffff',
    fontSize: '16px',
    fontWeight: 600,
    cursor: 'pointer',
    marginTop: '16px',
    textDecoration: 'underline',
  },
}))
