import React from 'react'
import styles from './styles'
import useLanguage from 'locale'

const ShowEmailSentSuccessMsg = () => {
  const classes = styles()
  const translations = useLanguage()
  return (
    <div className={classes.message_container}>
      <div className={classes.message_div}>
        <p>{translations.forgetPassword.messages.customerView.emailSentMsg}</p>
      </div>
    </div>
  )
}

export default ShowEmailSentSuccessMsg
