import React from 'react'
import PropTypes from 'prop-types'
import classNames from 'classnames'
import { Grid, Box, Link } from '@material-ui/core'
import { useDispatch } from 'react-redux'

import {
  sessionEndAction,
  clearDiamondAction,
  clearSelectedDiamondAction,
  clearLanguageAction,
  clearEmailTemplateAction,
  clearFooterAction,
  clearIntroductionAction,
} from 'store/actions'
import { ReactComponent as ExitSVG } from 'assets/icons/exit.svg'
import { ReactComponent as BackSVG } from 'assets/icons/back.svg'
import { ReactComponent as LogoSVG } from 'assets/icons/logo.svg'
import testIds from 'config/testIds'

import styles from './styles'

export const Header = ({
  children,
  actionText: TextComponent,
  action,
  actionActive,
  withHistoryAction = true,
  isDiamondPage = false,
  isRecipientView = false,
  onBack,
}) => {
  const classes = styles()
  const dispatch = useDispatch()
  const ids = testIds.header

  const clearStoreOnSessionEnd = () => {
    dispatch(sessionEndAction())
    dispatch(clearDiamondAction())
    dispatch(clearSelectedDiamondAction())
    dispatch(clearLanguageAction())
    dispatch(clearEmailTemplateAction())
    dispatch(clearFooterAction())
    dispatch(clearIntroductionAction())
  }

  return (
    <Grid
      container
      justify="space-between"
      alignItems="center"
      wrap="nowrap"
      className={classes.container}
      data-test-id={ids.container}
    >
      <Grid item>
        <Grid container alignItems="center" spacing={1}>
          {withHistoryAction ? (
            onBack ? (
              <Grid item onClick={onBack} className={classes.actionable} data-test-id={ids.backButton}>
                <BackSVG />
              </Grid>
            ) : (
              <Grid
                item
                onClick={clearStoreOnSessionEnd}
                className={classes.actionable}
                data-test-id={ids.logoutButton}
              >
                <ExitSVG />
              </Grid>
            )
          ) : null}
          <Grid item>
            {isRecipientView ? (
              <a href="https://www.tracr.com/" target="_blank" rel="noopener noreferrer" data-test-id={ids.tracrLink}>
                <LogoSVG />
              </a>
            ) : (
              <LogoSVG />
            )}
          </Grid>
        </Grid>
      </Grid>
      <Box className={classes.textLogo} data-test-id={ids.textLogo}>
        {children}
      </Box>
      {action && (
        <Grid
          item
          className={classNames({
            [classes.linkContainer]: true,
            [classes.activeLink]: actionActive,
            [classes.diamonPageStyle]: isDiamondPage,
            [classes.diamonPageStyleActive]: isDiamondPage && actionActive,
          })}
        >
          <Link
            className={classNames({
              [classes.link]: true,
              [classes.activeLink]: actionActive,
              [classes.diamonPageStyleActive]: isDiamondPage && actionActive,
            })}
            onClick={action}
            data-test-id={ids.selectButton}
          >
            {TextComponent && <TextComponent />}
          </Link>
        </Grid>
      )}
    </Grid>
  )
}

Header.propTypes = {
  action: PropTypes.func.isRequired,
  actionText: PropTypes.func.isRequired,
  actionActive: PropTypes.bool.isRequired,
  children: PropTypes.node.isRequired,
}

export default Header
