import makeStyles from '@material-ui/core/styles/makeStyles'

export default makeStyles((theme) => {
  return {
    container: {
      padding: (props) => (props.padding ? props.padding : '40px 0'),
      marginTop: '40px',
      userSelect: 'none',
    },
    image: {
      width: '100%',
      display: 'block',
      margin: '0 auto',
      padding: '0 32px',
      maxWidth: (props) => `${props.imageWidth + 32 * 2}px`,
    },
  }
})
