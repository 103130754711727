import env from '@beam-australia/react-env'
import TextField from '@material-ui/core/TextField'
import Typography from '@material-ui/core/Typography'
import axios from 'axios'
import GTM_Event_TYPES from 'GTM/model'
import { deployGTMDataLayer } from 'GTM/service'
import useLanguage from 'locale'
import React, { useState } from 'react'
import { useDispatch } from 'react-redux'
import { useHistory } from 'react-router-dom'
import { sessionStartAction } from 'store/actions'
import { validEmail } from 'utils/helper'
import styles from './styles'

const Login = () => {
  const classes = styles()
  const history = useHistory()
  const translations = useLanguage()

  const [identifier, setIdentifier] = useState('')
  const [password, setPassword] = useState('')
  const [loginFailMsg, setLoginFailMsg] = useState('')
  const [jwt, setJWT] = React.useState(null)

  const dispatch = useDispatch()
  const loginErrCheck = {
    INVALID_EMAIL: 'invalid email',
    INVALID_PASSWORD: 'invalid password',
  }
  const loginErrorMsg = {
    invalidEmail: translations.login.messages.customerView.invalidEmail,
    invalidPassword: translations.login.messages.customerView.invalidPassword,
    inCorrectCombi: translations.login.messages.customerView.inCorrectCombination,
    requestFailure: translations.login.messages.customerView.requestFailure,
  }

  const analyticsMsg = {
    inCorrectCombi: 'Invalid email & password combination',
    requestFailure: 'Login Failed. System administrator needed',
    invalidEmail: 'Invalid email',
    invalidPassword: 'Invalid password',
  }

  const onLogInClick = (e) => {
    e.preventDefault()

    try {
      if (!identifier || !validEmail(identifier)) {
        throw new Error(loginErrCheck.INVALID_EMAIL)
      } else if (!password) {
        throw new Error(loginErrCheck.INVALID_PASSWORD)
      }

      const endpoint = env('API_URL') + '/auth/local'

      const postData = { identifier, password }
      axios
        .post(endpoint, postData)
        .then((response) => {
          setJWT(response.data.jwt)
          history.push('/list')
          deployGTMDataLayer({
            event: GTM_Event_TYPES.LOGIN_SUCCESS,
            loginOutCome: 'Login is successful',
          })
        })
        .catch((err) => {
          console.error('Error during login:', err)
          if (err?.response?.status === 400) {
            setLoginFailMsg(loginErrorMsg.inCorrectCombi)
          } else {
            setLoginFailMsg(loginErrorMsg.requestFailure)
          }

          deployGTMDataLayer({
            event: GTM_Event_TYPES.LOGIN_FAILURE,
            loginOutCome: err?.response?.status === 400 ? analyticsMsg.invalidEmail : analyticsMsg.requestFailure,
          })
        })
    } catch (err) {
      setLoginFailMsg(
        err.message === loginErrCheck.INVALID_EMAIL ? loginErrorMsg.invalidEmail : loginErrorMsg.invalidPassword
      )
      deployGTMDataLayer({
        event: GTM_Event_TYPES.LOGIN_FAILURE,
        loginOutCome: loginErrCheck.INVALID_EMAIL ? analyticsMsg.invalidEmail : analyticsMsg.invalidPassword,
      })
    }
  }

  React.useEffect(() => {
    dispatch(sessionStartAction(jwt))
  }, [dispatch, jwt])

  return (
    <form className={classes.loginFacility} noValidate onSubmit={onLogInClick}>
      <div>
        <TextField
          placeholder={translations.login.messages.customerView.enterEmail}
          value={identifier}
          onChange={(e) => setIdentifier(e.target.value)}
          InputProps={{ classes: { input: classes.input } }}
        />
      </div>
      <div style={{ marginTop: '10px' }}>
        <TextField
          placeholder={translations.login.messages.customerView.enterPassword}
          type="password"
          value={password}
          onChange={(e) => setPassword(e.target.value)}
          InputProps={{ classes: { input: classes.input } }}
        />
      </div>
      {loginFailMsg && (
        <div>
          <Typography variant="subtitle2" className={classes.errorStatus}>
            {loginFailMsg}
          </Typography>
        </div>
      )}
      <div style={{ marginTop: 34 }}>
        <button className={classes.button} variant="contained" color="primary" type={'submit'} onClick={onLogInClick}>
          {translations.login.button.loginButtonText}
        </button>
      </div>
    </form>
  )
}

export default Login
