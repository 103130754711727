import makeStyles from '@material-ui/core/styles/makeStyles'

export default makeStyles((theme) => ({
  diamondVideoContainer: {
    '& video': {
      width: '100%',
      verticalAlign: 'top',
    },
  },
}))
