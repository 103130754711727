import React from 'react'

import Banner from 'components/Banner'
import Header from './Header'
import Address from './Address'
import AddressCity from './Address/City'
import AddressLine from './Address/Line'
import AddressPhone from './Address/Phone'
import AddressEmail from './Address/Email'

import styles from './styles'
import testIds from 'config/testIds'
import useLanguage from 'locale'

const ShareUnavailable = () => {
  const classNames = styles()
  const translations = useLanguage()

  return (
    <div className={classNames.container}>
      <Header
        titleLong={translations.shareUnavailable.headerTitleLong}
        titleShort={translations.shareUnavailable.headerTitleShort}
      />
      <Banner imageWidth={346} data-test-id={testIds.shareUnavailable.banner} isLink={true} />
      <div className={classNames.titleContainer}>
        <div className={classNames.title} data-test-id={testIds.shareUnavailable.title}>
          {translations.shareUnavailable.title}
        </div>
        <div className={classNames.subTitle} data-test-id={testIds.shareUnavailable.subtitle}>
          {translations.shareUnavailable.subtitle}
        </div>
      </div>
      <div className={classNames.addressContainer}>
        <Address data-test-id={testIds.shareUnavailable.address1}>
          <AddressCity>London</AddressCity>
          <AddressLine>50 Old Bond Street</AddressLine>
          <AddressLine>London</AddressLine>
          <AddressLine>W1S 4QT</AddressLine>
          <AddressPhone>
            <a href="tel:+44 20 7758 9700">+44 20 7758 9700</a>
          </AddressPhone>
          <AddressEmail>
            <a href="mailto:debeersoldbondstreet@debeers.com">debeersoldbondstreet@debeers.com</a>
          </AddressEmail>
        </Address>
        <Address data-test-id={testIds.shareUnavailable.address2}>
          <AddressCity>Macau</AddressCity>
          <AddressLine>Macau T Galleria By DFS Four Seasons Pop-up Store</AddressLine>
          <AddressLine>Level 1</AddressLine>
          <AddressLine>The Shoppes at Four Seasons</AddressLine>
          <AddressLine>Cotai Strip®</AddressLine>
          <AddressLine>Macau</AddressLine>
          <AddressPhone>
            <a href="tel:+85328823298">+85328823298</a>
          </AddressPhone>
          <AddressEmail>
            <a href="mailto:debeersmacau@debeers.com">debeersmacau@debeers.com</a>
          </AddressEmail>
        </Address>
      </div>
    </div>
  )
}

export default ShareUnavailable
