import makeStyles from '@material-ui/core/styles/makeStyles'

export default makeStyles(() => ({
  container: {
    display: 'grid',
    gridTemplateRows: '1fr 3fr 3fr 1fr',
    background: 'url(images/background.png) center center / cover no-repeat',
    height: '100vh',
  },
  item: {
    display: 'flex',
    /* justifyContent: 'center', */
    /* margin: '69', */
    alignItems: 'center',
    flexDirection: 'column',
  },
  start: {
    alignItems: 'flex-start',
  },
  tritem: {
    display: 'flex',
    alignItems: 'center',
    flexDirection: 'column',
    justifyContent: 'center',
  },
  actionable: {
    cursor: 'pointer',
    padding: '5px',
  },
}))
