import env from '@beam-australia/react-env'
import TagManager from 'react-gtm-module'
import { isProductionEnvironment } from 'utils/helper'

function initializeTagManager() {
  if (isProductionEnvironment()) {
    const tagManagerArgs = {
      gtmId: env('GTM_ID'),
    }

    TagManager.initialize(tagManagerArgs)
  }
}

function deployGTMDataLayer(dataLayerObj) {
  if (isProductionEnvironment()) {
    const tagManagerArgs = {
      dataLayer: {
        ...dataLayerObj,
      },
    }
    TagManager.dataLayer(tagManagerArgs)
  }
}

export { deployGTMDataLayer, initializeTagManager }
