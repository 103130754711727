import { createMuiTheme } from '@material-ui/core/styles'
// import merge from 'lodash/merge'

import { defaultStyles } from './defaultStyles'

// import card from './card'
// const dynamicOverrides = merge(card)

// defaultStyles.overrides = dynamicOverrides

export const theme = createMuiTheme(defaultStyles)
