import http from '../utils/http'

const namespace = 'user'

const login = (username, password) => {
  return http.post(`${namespace}/login`, { username, password })
}

const logout = () => {
  return http.post(`${namespace}/logout`)
}

const loadUserData = () => {
  return http.get(`${namespace}`)
}

export default {
  login,
  logout,
  loadUserData,
}
