import React from 'react'
import testIds from 'config/testIds'

import styles from './styles'

const Phone = ({ children }) => {
  const classNames = styles()

  return (
    <div className={classNames.phone} data-test-id={testIds.address.phone}>
      {children}
    </div>
  )
}

export default Phone
