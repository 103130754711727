import makeStyles from '@material-ui/core/styles/makeStyles'

export default makeStyles((theme) => ({
  diamondProvenanceContainer: {},
  provenanceContent: {
    position: 'relative',
    height: '200px',
    overflow: 'hidden',
    '& img': {
      verticalAlign: 'top',
      position: 'absolute',
      width: '185%',
    },
    '@media (min-width: 1024px)': {
      height: '300px',
      '& img': {
        width: '100%',
      },
    },
    '@media (min-width: 1440px)': {
      height: '428px',
    },
  },
  provenanceTitleContainer: {
    position: 'absolute',
    top: '50%',
    transform: 'translateY(-50%)',
    boxSizing: 'border-box',
    width: '100%',
    padding: '0 32px',
    '@media (min-width: 1024px)': {
      padding: '0 127px',
    },
  },
  provenanceTitleLabel: {
    textAlign: 'center',
    ...theme.typography.sizeM,
    fontWeight: 400,
    lineHeight: '23px',
    color: theme.colors.white,
    '@media (min-width: 1024px)': {
      fontSize: '26px',
      lineHeight: 1,
    },
    '@media (min-width: 1440px)': {
      fontSize: '34px',
    },
  },
  provenanceTitleLabelBreak: {
    '@media (min-width: 1024px)': {
      display: 'none',
    },
  },
  provenanceTitleCountry: {
    margin: 0,
    textAlign: 'center',
    fontSize: '36px',
    fontWeight: 400,
    letterSpacing: '0.04px',
    textTransform: 'uppercase',
    color: theme.colors.white,
    '@media (min-width: 1024px)': {
      fontSize: '60px',
    },
    '@media (min-width: 1440px)': {
      fontSize: '72px',
    },
  },
  provenanceTitleCountryChinese: {
    paddingTop: 0,
    '@media (min-width: 1024px)': {},
    '@media (min-width: 1440px)': {},
  },
  provenanceTitleBottomLine: {
    ...theme.typography.sizeM,
    color: theme.colors.white,
    textAlign: 'center',
    '@media (min-width: 1024px)': {
      fontSize: '26px',
    },
    '@media (min-width: 1440px)': {
      fontSize: '34px',
    },
  },
}))
