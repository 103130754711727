import React from 'react'
import PropTypes from 'prop-types'
import { useSelector } from 'react-redux'
import env from '@beam-australia/react-env'

import useLanguage from 'locale'
import { selectIsDiamondSelectedById, selectSelectedDiamonds } from 'store/selectors'

import PageButton from 'components/PageButton'
import WarningBar from 'components/WarningBar'

const MAX_DIAMONDS_PER_SHARE = env('MAX_DIAMONDS_PER_SHARE') || 3

const PageButtons = ({ dbMarque, handleContinue }) => {
  const translation = useLanguage()
  const selectedDiamonds = useSelector(selectSelectedDiamonds)
  const isSelected = useSelector(selectIsDiamondSelectedById(dbMarque))

  const limitReached = selectedDiamonds.length > MAX_DIAMONDS_PER_SHARE

  return (
    <>
      {(!dbMarque || (dbMarque && isSelected)) && (
        <>
          {!limitReached && selectedDiamonds.length > 0 && (
            <PageButton key={'page-button'} onClick={handleContinue}>
              {translation.list.continue}
            </PageButton>
          )}
          {limitReached && <WarningBar max={MAX_DIAMONDS_PER_SHARE} currentSelectedCount={selectedDiamonds.length} />}
        </>
      )}
    </>
  )
}

PageButtons.propTypes = {
  dbMarque: PropTypes.string,
  handleContinue: PropTypes.func.isRequired,
}

export default PageButtons
