import http from 'utils/http'
const diamondsNamespace = 'diamonds'
const sharesNamespace = 'shares'

const shareDiamonds = (body) => http.post(`${sharesNamespace}`, body)

const shareDiamondsToEmail = (id, body) => http.post(`share/${id}`, body)

const getDiamonds = (lang) => {
  return http.get(`${diamondsNamespace}?_limit=-1&_locale=${lang}`)
}

const getSharedDiamonds = (id, lang) => {
  return http.get(`shares/${id}/locale/${lang}`)
}

export default {
  shareDiamonds,
  shareDiamondsToEmail,
  getDiamonds,
  getSharedDiamonds,
}
