import React from 'react'
import testIds from 'config/testIds'

import styles from './styles'

const Email = ({ children }) => {
  const classNames = styles()

  return (
    <div className={classNames.email} data-test-id={testIds.address.email}>
      {children}
    </div>
  )
}

export default Email
