import { SELECTED_ACTION_TYPES } from '../actionTypes'

export function selectedSelectDiamond(payload) {
  return {
    type: SELECTED_ACTION_TYPES.SELECT_DIAMOND,
    payload,
  }
}

export function selectedDeselectDiamond(payload) {
  return {
    type: SELECTED_ACTION_TYPES.DESELECT_DIAMOND,
    payload,
  }
}

export function clearSelectedDiamondAction() {
  return (dispatch) => {
    dispatch({ type: SELECTED_ACTION_TYPES.CLEAR_SELECTION })
  }
}
