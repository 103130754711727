import makeStyles from '@material-ui/core/styles/makeStyles'

export default makeStyles((theme) => ({
  container: {
    background: 'rgb(13,27,64, 0.1)',
    color: '#0D1B40',
    userSelect: 'none',
  },
  action: {
    textAlign: 'center',
    cursor: 'pointer',
    padding: '12px',
    position: 'relative',
    fontFamily: 'Montserrat',
    fontSize: theme.typography.subtitle2.fontSize,
    fontWeight: 600,
  },
  singleAction: {
    maxWidth: '100%',
    flexBasis: '100%',
  },
  notLastAction: {
    '&::before': {
      content: '""',
      position: 'absolute',
      right: 0,
      top: '9px',
      height: '24px',
      borderRight: '1px solid rgba(255, 255, 255, 0.2)',
    },
  },
  disabled: {
    color: 'rgba(255, 255, 255, 0.34)',
    cursor: 'no-drop',
  },
  selected: {
    background: '#0D1B40',
    color: '#ffffff',
  },
}))
