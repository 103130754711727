import React from 'react'
import PropTypes from 'prop-types'
import { Button } from '@material-ui/core'

import testIds from 'config/testIds'

import styles from './styles'

const PageButton = ({ onClick, disabled, children }) => {
  const classes = styles()
  const ids = testIds.footer

  return (
    <Button disabled={disabled} onClick={onClick} className={classes.container} data-test-id={ids.continueButtom}>
      {children}
    </Button>
  )
}

PageButton.propTypes = {
  children: PropTypes.string.isRequired,
  onClick: PropTypes.func.isRequired,
  disabled: PropTypes.bool,
}

export default PageButton
