import user from './user'
import diamonds from './diamonds'
import footer from './footer'
import introduction from './introduction'
import emailTemplate from './emailTemplate'

const API = {
  ...user,
  ...diamonds,
  ...footer,
  ...introduction,
  ...emailTemplate,
}

export default API
