import makeStyles from '@material-ui/core/styles/makeStyles'

export default makeStyles(() => ({
  container: {
    width: '100%',
  },
  media: {
    position: 'relative',
    maxWidth: (props) => (props.imageSize ? `${props.imageSize.width}px` : null),
    height: (props) => (props.imageSize ? `${props.imageSize.height}px` : null),
    overflow: 'hidden',
    margin: '0 auto',
    userSelect: 'none',
  },
  mediaInner: {
    position: 'absolute',
    maxWidth: '100%',
    top: '50%',
    left: '50%',
    transform: 'translate( -50%, -50%)',
    width: '100%',
  },
  shape: {
    padding: '0 10px',
  },
}))
