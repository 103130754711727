import { Box } from '@material-ui/core'
import Banner from 'components/Banner'
import CardView from 'containers/CardView'
import { Header } from 'containers/Header'
import LanguageSelector from 'containers/LanguageSelector'
import GTM_Event_TYPES from 'GTM/model'
import { deployGTMDataLayer } from 'GTM/service'
import useLanguage from 'locale'
import React from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { selectedDeselectDiamond } from 'store/actions'
import { selectSelectedDiamonds } from 'store/selectors'

const List = () => {
  const selectedDiamonds = useSelector(selectSelectedDiamonds)
  const dispatch = useDispatch()
  const translations = useLanguage()

  const handleCancelEditing = () => {
    // Remove all diamonds button handled
    const diamondDbMarqueArr = selectedDiamonds.map((eachDiamondDbMarque) => ({ dbMarque: eachDiamondDbMarque }))

    deployGTMDataLayer({
      event: GTM_Event_TYPES.REMOVE_ALL_DIAMONDS,
      dbMarques: diamondDbMarqueArr,
    })

    const nextState = !editing
    if (nextState === false) {
      selectedDiamonds.forEach((dbMarque) => {
        dispatch(selectedDeselectDiamond({ dbMarque }))
      })
    }
  }

  const editing = selectedDiamonds.length > 0
  const actionText = () => <>{translations.list.removeAll}</>
  return (
    <>
      <Header actionText={actionText} actionActive={editing} action={() => handleCancelEditing()}>
        <Box>{translations.list.title}</Box>
      </Header>
      <Banner imageWidth={362} />
      <CardView selectedDiamonds={selectedDiamonds} editing={editing} />
      <LanguageSelector />
    </>
  )
}

export default List
