import React, { useState, useCallback } from 'react'
import PropTypes from 'prop-types'
import { Chip } from '@material-ui/core'
import { ReactComponent as CloseSVG } from 'assets/icons/close.svg'
import makeStyles from './styles'

const Tag = ({ id, value, valid, editable, onDelete, onUpdate }) => {
  const classes = makeStyles()

  const [tag, setTag] = useState(value)
  const [editing, setEditing] = useState(false)
  const [width, setWidth] = useState(0)
  const [height, setHeight] = useState(0)

  const onTagMounted = useCallback((ref) => {
    if (ref) {
      const widthAddition = 2
      const heightAddition = 2

      setWidth(ref.clientWidth + widthAddition)
      setHeight(ref.clientHeight + heightAddition)
    }
  }, [])

  const onTagEditingMounted = useCallback((ref) => ref && ref.focus(), [])

  const handleKeyDown = (event) => {
    if (['Tab', 'Enter'].includes(event.key)) {
      processUpdate(event)
      setEditing(false)
    }
  }

  const handleBlur = (event) => {
    processUpdate(event)
    setEditing(false)
  }

  const handleChange = (event) => {
    setTag(event.target.value)
  }

  const processUpdate = (event) => {
    const newValue = event.target.value

    if (onUpdate && newValue !== value) {
      onUpdate(id, newValue)
    }
  }

  if (editing) {
    const dynamicStyles = {
      width,
      height,
    }

    return (
      <span className={classes.container}>
        <input
          ref={onTagEditingMounted}
          onChange={handleChange}
          onKeyDown={handleKeyDown}
          onBlur={handleBlur}
          className={classes.editInput}
          style={width && height ? dynamicStyles : {}}
          value={tag}
        />
      </span>
    )
  }

  return (
    <span className={classes.container}>
      <Chip
        ref={onTagMounted}
        {...(onDelete ? { onDelete: () => onDelete(id) } : {})}
        classes={{
          root: classes.root,
          label: classes.label,
          outlined: valid ? classes.valid : classes.invalid,
          deleteIcon: valid ? classes.deleteIcon : classes.invalidDeleteIcon,
        }}
        deleteIcon={<CloseSVG />}
        variant="outlined"
        label={tag}
      />
    </span>
  )
}

Tag.defaultProps = {
  editable: false,
}

Tag.propTypes = {
  id: PropTypes.string,
  value: PropTypes.string,
  editable: PropTypes.bool,
  onDelete: PropTypes.func,
  onUpdate: PropTypes.func,
}

export default Tag
