import API from 'api'
import { DIAMONDS_ACTION_TYPES } from '../actionTypes'
import { sessionExpiredAction } from './'

export function getDiamondAction(diamond, lang) {
  return (dispatch) => {
    dispatch({ type: DIAMONDS_ACTION_TYPES.GET_DIAMOND.REQUEST })

    return API.getDiamonds(lang).then(
      (response) => {
        let diamondContent = {}
        if (response.data.length > 0) {
          diamondContent = response.data.find((eachDiamond) => eachDiamond.dbMarque === diamond.dbMarque)
        }
        dispatch({ type: DIAMONDS_ACTION_TYPES.GET_DIAMOND.COMPLETE, payload: diamondContent })
      },
      (error) => {
        if (error.response && error.response.status === 401) {
          dispatch(sessionExpiredAction())
        } else {
          dispatch({ type: DIAMONDS_ACTION_TYPES.GET_DIAMOND.FAILURE, payload: error })
        }
      }
    )
  }
}

export function getSharedDiamondsAction(id, lang) {
  return (dispatch) => {
    dispatch({ type: DIAMONDS_ACTION_TYPES.GET_SHARED_DIAMONDS.REQUEST })

    return API.getSharedDiamonds(id, lang)
      .then((response) => {
        return dispatch({ type: DIAMONDS_ACTION_TYPES.GET_SHARED_DIAMONDS.COMPLETE, payload: response.data })
      })
      .catch((response) => {
        const error = JSON.parse(JSON.stringify(response))

        if (error.message && error.message.includes('404')) {
          dispatch({ type: DIAMONDS_ACTION_TYPES.GET_SHARED_DIAMONDS.COMPLETE, payload: false })
        }
      })
  }
}

export function shareDiamondsAction(body) {
  return (dispatch) => {
    dispatch({ type: DIAMONDS_ACTION_TYPES.SHARE_DIAMOND_TO_EMAIL.REQUEST })
    return API.shareDiamonds(body).then(
      (response) => {
        return dispatch({
          type: DIAMONDS_ACTION_TYPES.SHARE_DIAMOND_TO_EMAIL.COMPLETE,
          payload: response.data.content,
        })
      },
      (error) => {
        dispatch({ type: DIAMONDS_ACTION_TYPES.SHARE_DIAMOND_TO_EMAIL.FAILURE, payload: error })
      }
    )
  }
}
export function getDiamondsAction(lang) {
  return (dispatch) => {
    dispatch({ type: DIAMONDS_ACTION_TYPES.GET_DIAMONDS.REQUEST })

    return API.getDiamonds(lang).then(
      (response) => {
        return dispatch({
          type: DIAMONDS_ACTION_TYPES.GET_DIAMONDS.COMPLETE,
          payload: response.data,
        })
      },
      (error) => {
        if (error.response && error.response.status === 401) {
          dispatch(sessionExpiredAction())
        } else {
          dispatch({ type: DIAMONDS_ACTION_TYPES.GET_DIAMONDS.FAILURE, payload: error })
        }
      }
    )
  }
}

export function clearDiamondAction() {
  return (dispatch) => {
    dispatch({ type: DIAMONDS_ACTION_TYPES.CLEAR_DIAMONDS.CLEAR })
  }
}
