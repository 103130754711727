import { FOOTER_ACTION_TYPES } from '../actionTypes'
import API from 'api'

export function getFooterAction(lang) {
  return (dispatch) => {
    return API.getFooter(lang).then(
      (response) => {
        return dispatch({
          type: FOOTER_ACTION_TYPES.SET_FOOTER,
          payload: response.data,
        })
      },
      (error) => {
        console.log('footer fetching error: ', error.message)
      }
    )
  }
}

export function clearFooterAction() {
  return (dispatch) => {
    dispatch({ type: FOOTER_ACTION_TYPES.CLEAR_FOOTER })
  }
}
