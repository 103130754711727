import React from 'react'
import testIds from 'config/testIds'

import styles from './styles'

const Address = ({ children }) => {
  const classNames = styles()

  return (
    <div className={classNames.address} data-test-id={testIds.address.addressContainer}>
      {children}
    </div>
  )
}

export default Address
