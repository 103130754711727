import env from '@beam-australia/react-env'
import TextField from '@material-ui/core/TextField'
import Typography from '@material-ui/core/Typography'
import axios from 'axios'
import React, { useState } from 'react'
import { validEmail } from 'utils/helper'
import ShowEmailSentSuccessMsg from './ShowEmailSentSuccessMsg'
import { Link } from 'react-router-dom'
import styles from './styles'
import useLanguage from 'locale'

const ForgotPassword = () => {
  const classes = styles()
  const translations = useLanguage()

  const [email, setEmail] = useState('')
  const [emailFailMsg, setEmailFailMsg] = useState('')
  const [emailSendSuccess, setEmailSendSuccess] = useState(false)

  const forgetPassMsg = {
    emailError: translations.forgetPassword.messages.customerView.emailError,
    checkEmail: translations.forgetPassword.messages.customerView.checkEmail,
    requestFailure: translations.forgetPassword.messages.customerView.requestFailure,
  }

  const onEmailChange = (e) => {
    setEmail(e.target.value)
  }

  const onEmailSubmit = (e) => {
    e.preventDefault()

    try {
      if (!email || !validEmail(email)) {
        throw new Error(forgetPassMsg.emailError)
      }

      const endpoint = env('API_URL') + '/auth/forgot-password'

      const postData = { email }
      axios
        .post(endpoint, postData)
        .then((response) => {
          setEmailSendSuccess(true)
        })
        .catch((err) => {
          if (err.response.status === 400) {
            setEmailFailMsg(forgetPassMsg.checkEmail)
          } else {
            setEmailFailMsg(forgetPassMsg.requestFailure)
          }
        })
    } catch (error) {
      console.error('Error during forgot-password:', error.message)
      setEmailFailMsg(forgetPassMsg.emailError)
    }
  }

  return (
    <>
      {emailSendSuccess ? (
        <ShowEmailSentSuccessMsg />
      ) : (
        <form className={classes.loginFacility} noValidate onSubmit={onEmailSubmit}>
          <div>
            <TextField
              placeholder={translations.forgetPassword.messages.customerView.enterEmail}
              value={email}
              onChange={onEmailChange}
              InputProps={{ classes: { input: classes.input } }}
            />
          </div>
          {emailFailMsg && (
            <div>
              <Typography variant="subtitle2" className={classes.errorStatus}>
                {emailFailMsg}
              </Typography>
            </div>
          )}
          <div className={classes.forgetpass_div}>
            <button
              className={classes.button}
              variant="contained"
              color="primary"
              type={'submit'}
              onClick={onEmailSubmit}
            >
              {translations.forgetPassword.button.submit}
            </button>
            <div className={classes.linkstyle}>
              <Link to="/login">{translations.forgetPassword.button.loginInstead}</Link>
            </div>
          </div>
        </form>
      )}
    </>
  )
}

export default ForgotPassword
