import makeStyles from '@material-ui/core/styles/makeStyles'

export default makeStyles((theme) => ({
  diamondPropContainer: {},
  prop: {
    borderBottom: `1px solid ${theme.colors.darkBlue}`,
  },
  label: {
    fontFamily: 'Futura',
    fontSize: '12px',
    fontWeight: 400,
    lineHeight: '15px',
    letterSpacing: '0.01px',
    color: '#787878',
    opacity: 0.7,
    '@media (min-width: 1024px)': {},
  },
  value: {
    padding: '6px 0 4px',
    fontFamily: 'Futura',
    fontSize: '16px',
    fontWeight: 400,
    letterSpacing: '0.02px',
    color: theme.colors.darkBlue,
    '@media (min-width: 1024px)': {},
    '@media (min-width: 1440px)': {
      padding: '6px 0 12px 0',
    },
  },
}))
