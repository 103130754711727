import React from 'react'
import { Box } from '@material-ui/core'

import styles from './styles'

const DiamondProp = ({ label, value, testId }) => {
  const classes = styles()

  if (!label || !value) return null

  return (
    <Box className={classes.diamondPropContainer} data-test-id={testId}>
      <Box className={classes.prop}>
        <Box className={classes.label}>{label}</Box>
        <Box className={classes.value}>{value}</Box>
      </Box>
    </Box>
  )
}

export default DiamondProp
