import env from '@beam-australia/react-env'
import { ThemeProvider } from '@material-ui/core'
import CssBaseline from '@material-ui/core/CssBaseline'
import PropTypes from 'prop-types'
import React, { createContext } from 'react'
import { BrowserRouter as Router } from 'react-router-dom'
import CustomRouter from './CustomRouter'
export const ConfigContext = createContext()

/**
 * Top level application component.
 * @reactProps {object} config Application config
 */
export const App = function ({ config = {} }) {
  const VERSION = env('VERSION') || 'NOT_SET'
  // INITIAL way version loggin, can be improved
  console.log(
    `%cApplication version: %c ${VERSION}`,
    'background: #202020; color: #fff; font-size: 14px',
    'color: #202020; font-size: 14px'
  )

  return (
    <ConfigContext.Provider value={config}>
      <ThemeProvider theme={config.theme}>
        <CssBaseline />
        <Router>
          <CustomRouter></CustomRouter>
        </Router>
      </ThemeProvider>
    </ConfigContext.Provider>
  )
}

App.propTypes = {
  config: PropTypes.object,
}

export default App
