import { STORE_INIT, FOOTER_ACTION_TYPES } from '../actionTypes'

const initialState = {
  footer: {},
}

const footerReducer = (state = initialState, { type, payload }) => {
  switch (type) {
    case STORE_INIT: {
      return state
    }
    case FOOTER_ACTION_TYPES.SET_FOOTER: {
      return {
        footer: payload,
      }
    }
    case FOOTER_ACTION_TYPES.CLEAR_FOOTER: {
      return initialState
    }

    default:
      return state
  }
}

export default footerReducer
