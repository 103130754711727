import React from 'react'
import PropTypes from 'prop-types'
import Grid from '@material-ui/core/Grid'
import testIds from 'config/testIds'

import classNames from 'classnames'

import styles from './styles'

const CardActions = ({ actions }) => {
  const classes = styles()
  return (
    <Grid container direction="column">
      <Grid item className={classes.container}>
        <Grid container direction="row">
          {actions.map((action, index) => (
            <Grid
              data-test-id={testIds.cardView.cardButton}
              key={index}
              item
              xs={12 / actions.length}
              className={classNames({
                [classes.action]: true,
                [classes.disabled]: false,
                [classes.selected]: action.selected,
                [classes.notLastAction]: index !== actions.length - 1,
                [classes.singleAction]: actions.length === 0,
              })}
              {...action.events}
            >
              {action.text}
            </Grid>
          ))}
        </Grid>
      </Grid>
    </Grid>
  )
}

CardActions.propTypes = {
  actions: PropTypes.arrayOf(
    PropTypes.shape({
      events: PropTypes.shape({
        onClick: PropTypes.func,
      }),
      text: PropTypes.string.isRequired,
      disabled: PropTypes.bool.isRequired,
      selected: PropTypes.bool.isRequired,
    }).isRequired
  ).isRequired,
}

export default CardActions
