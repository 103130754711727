import makeStyles from '@material-ui/core/styles/makeStyles'

export default makeStyles((theme) => ({
  container: {
    fontFamily: 'Montserrat',
  },
  header: {
    background: '#1D1D1D',
    fontWeight: 600,
    padding: 25,
  },
  title: {
    color: '#0D1B40',
    fontWeight: 600,
    fontSize: 22,
  },
  titleEmail: {
    marginLeft: '-1px',
  },
  banner: {
    padding: '95px 0 65px',
  },
  shareButton: {
    width: '134px',
    border: '2px solid #18BC98',
    background: '#18BC98',
    fontWeight: 600,
    '&:hover': {
      background: '#18BC98',
    },
  },
  emailInputContainer: {
    borderBottom: '1px solid #D3D6DA',
    marginBottom: 18,
    alignItems: 'center',
  },
  content: {
    padding: 25,
    paddingBottom: 70,
    maxWidth: 788,
    margin: '0 auto',
  },
  inputContainer: {
    alignItems: 'center',
    border: '1px solid #D3D6DA',
    padding: '12px 24px',
  },
  inputContainerActive: {
    border: '1px solid #0D1B40',
  },
  inputContainerError: {
    border: '1px solid #C20746',
  },
  error: {
    color: '#C20746',
  },
  disabled: {
    background: 'rgba(255, 255, 255, 0.12)',
    border: 'none',
    color: 'rgba(255, 255, 255, 0.5)',
  },
  inputLabel: {
    fontWeight: 600,
    fontSize: 18,
  },
  phoneInputLabel: {
    fontWeight: 600,
    fontSize: 18,
    marginRight: 8,
  },
  textInput: {
    fontFamily: 'Montserrat',
    fontSize: 18,
    '-webkit-text-fill-color': '#000',
  },
  emailInput: {
    flexGrow: 1,
  },
  phoneInput: {
    flexGrow: 1,
    marginRight: 2,
  },
  emailRoot: {
    color: '#fff',
    width: '100%',
  },
  info: {
    color: '#8e8e8e',
  },
  item: {
    marginBottom: 10,
  },
  formElement: {
    marginBottom: 30,
  },
  textarea: {
    background: '#fafafa',
    width: '100%',
    border: '1px solid #D3D6DA',
    padding: 24,
    marginTop: 8,
    fontSize: 18,
    outline: 'none',
    '&:focus': {
      border: '1px solid #0D1B40',
    },
    color: '#000',
  },
  textareaError: {
    border: '1px solid #ff0000',
    '&:focus': {
      border: '1px solid #ff0000',
    },
  },
  bold: {
    fontWeight: 600,
  },
  shareButtonContainer: {
    width: '100%',
    borderRadius: 0,
    position: 'fixed',
    bottom: 0,
    background: '#0D1B40',
    height: 69,
    color: '#fff',
    fontWeight: 600,
    fontSize: 18,
    cursor: 'pointer',
  },
  privacyInfo: {
    color: '#0D1B40',
    fontSize: 10,
    fontWeight: 500,
    marginTop: 8,
    '& a': {
      color: '#0D1B40',
      borderBottom: '1px solid #0D1B40',
    },
  },
  errorMessage: {
    color: '#C20746',
    fontSize: 16,
    lineHeight: '22px',
    fontWeight: 500,
    marginTop: 16,
  },
  templateButtons: {
    marginBottom: '16px',
  },
  templateButton: {
    fontWeight: 600,
    cursor: 'pointer',
    border: 'none',
    outline: 'none',
    color: '#0D1B40',
    height: '40px',
    width: '100%',
    background: 'rgb(13, 27, 64, 0.1)',
  },
}))
