import React from 'react'
import testIds from 'config/testIds'

import styles from './styles'

const City = ({ children }) => {
  const classNames = styles()

  return (
    <div className={classNames.city} data-test-id={testIds.address.city}>
      {children}
    </div>
  )
}

export default City
