import makeStyles from '@material-ui/core/styles/makeStyles'

export default makeStyles(() => ({
  message_div: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    backgroundColor: 'white',
    fontSize: '18px',
    borderRadius: '5px',
    width: '50vw',
    height: '35vh',
  },
  message_container: {
    margin: '0 auto',
  },
}))
